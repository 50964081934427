import React, { Fragment, useState } from "react";
import Header from "./Header";
import { Form, Formik, useFormik } from "formik";
import * as yup from "yup";
import { Box, InputLabel, Modal, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  DecrementCount,
  IncrementCount,
  clearCart,
  otpaction,
} from "../redux/Actions";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import { currencyFormatter } from "../utils";

import {
  ETAtime,
  checkDiscountAvailability,
  getdeliverytime,
  getstorelist,
  orderbooking,
  orderbookingtwo,
  orderconfirm,
  placeorder,
  sendMessage,
  sendMessagetwo,
} from "../API";
import { useEffect } from "react";
import OTPInput, { ResendOTP } from "otp-input-react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import Footer from "./Footer";
function Cart() {
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const { cart, totalPrice } = useSelector((state) => state.IC);
  const [randomOrderId, setRandomOrderId] = useState(null);
  const [availableDiscount, setAvailableDiscount] = useState(null);
  const [discountMessage, setDiscountMessage] = useState(null);
  const [topDiscountCode, setTopDiscountCode] = useState(null);
  const [disablePlaceOrder, setDisablePlaceOrder] = useState(true);

  // if (
  //   !Array.isArray(cart) &&
  //   typeof cart === "object" &&
  //   localStorage.getItem("persist:root")
  // ) {
  //   localStorage.setItem(
  //     "persist:root",
  //     '{"IC":"{\\"cart\\":[],\\"totalitems\\":0,\\"totalPrice\\":0}","ItemsListReducer":"[]","otpNumberReducer":"[]","paymentReducer":"{\\"Payment_refrence\\":{}}","storeCurrencyReducer":"{}","storelanguagereducer":"{\\"storelanguage\\":\\"English\\"}","storemapIdreducer":"{}","_persist":"{\\"version\\":-1,\\"rehydrated\\":true}"}'
  //   );
  //   window.location.reload(false);
  // }

  const { storeCurrency } = useSelector((state) => state.storeCurrencyReducer);
  const { storelanguage } = useSelector((state) => state.storelanguagereducer);
  const { discountDetails } = useSelector(
    (state) => state.discountDetailsReducer
  );
  const placeorderSuccess = () => toast.success("Order Place successfully ");
  const placeorderError = () => toast.error("Can't place order");
  const [isorderplace, setisorderplace] = useState(false);
  const [Etatime, setEtaTime] = useState();
  const [Etaservice, setEtaservice] = useState();
  const cartdata = useLocation();
  const [otp, setOtp] = useState("");
  const [validateOTP, setValidateOTP] = useState(false);
  const navigate = useNavigate();
  const [count, setcount] = useState(0);
  const [newstring, setnewstring] = useState();

  const otpresend = () =>
    storelanguage === "English"
      ? toast.success("OTP send successfully")
      : toast.success("Envío OTP exitoso");
  const otpSuccess = () =>
    storelanguage === "English"
      ? toast.success("Valid OTP")
      : toast.success("OTP válida");
  const otpError = () =>
    storelanguage === "English"
      ? toast.error("Invalid OTP")
      : toast.error("OTP no válida");
  const Bookingerror = () =>
    storelanguage === "English"
      ? toast.error("Unexcepted Error")
      : toast.error("Error inesperado");

  let productsData = [];
  let mobilenumber = cartdata?.state?.contactNo;

  const storetype = localStorage.getItem("store_type");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    bgcolor: "#ffffff",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
    paddingTop: "11px",
    border: "none",
    paddingRight: "0px",
    paddingLeft: "0px",
    zIndex: "999999999999999999999999",
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };
  // console.log('cartdatacartdata',cartdata)
  useEffect(() => {
    ETAtime(cartdata?.state?.storeId)
      .then((res) => {
        setEtaTime(res.data.message);
        setEtaservice(res.data.service);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    let nstring = generateBarCode();
    setnewstring(nstring);
  }, []);

  function generateBarCode() {
    // var nric = $('#text').val();
    let rString = randomString(
      6,
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    );
    return rString;
  }

  const variantCount = (cartItem) => {
    if (Array.isArray(Object.values(cartItem)[0])) {
      let quantityCount = Object.values(cartItem)[0].map(
        (variantItem, index) => {
          return 1;
        }
      );
      return quantityCount;
    } else {
      return `${Object.values(cartItem)[0]}`;
    }
  };

  const CalculateVariantsQuantity = ({ cartItems }) => {
    let filteredCartItems = cartItems.reduce((uniqueVariant, variant) => {
      if (!uniqueVariant[Object.keys(variant)[0]]) {
        let uniqueVariantObject = Object.keys(variant).includes(
          "multipleVariants"
        )
          ? {
              variantCount: 1,
              product: Object.values(variant)[1],
              productVariant: Object.values(variant)[2],
              multipleVariants: Object.values(variant)[3],
            }
          : {
              variantCount: 1,
              product: Object.values(variant)[1],
              productVariant: Object.values(variant)[2],
            };
        uniqueVariant[Object.keys(variant)[0]] = uniqueVariantObject;
      } else if (uniqueVariant[Object.keys(variant)[0]]) {
        uniqueVariant[Object.keys(variant)[0]].variantCount += 1;
      }

      return uniqueVariant;
    }, []);

    return (
      <div className="flex flex-col gap-2">
        {Object.values(filteredCartItems).length > 0 &&
          Object.values(filteredCartItems).map((cartItem, index) => (
            <div
              key={"filtered__cart__item__no__" + index}
              // className="flex flex-col gap-1"
              className="grid grid-cols-[50%_50%] items-center"
            >
              <span className="text-[12px] capitalize">
                {cartItem?.multipleVariants?.length > 0 ? (
                  <>
                    {storelanguage === "English"
                      ? `${cartItem.multipleVariants
                          .map((variant) => variant.name.en)
                          .join(", ")
                          .toLowerCase()}`
                      : `${cartItem.multipleVariants
                          .map((variant) => variant.name.it)
                          .join(", ")
                          .toLowerCase()}`}
                  </>
                ) : (
                  <>
                    {storelanguage === "English"
                      ? `${cartItem.productVariant?.name?.en?.toLowerCase()}`
                      : `${cartItem.productVariant?.name?.it?.toLowerCase()}`}
                  </>
                )}
              </span>
              <div className="cart__product__list__container">
                <div className="cart__product__list__wrapper !border-none !shadow-none">
                  {/* <div
                    key={"variant__increment__modal__variant__div__" + index}
                    className="!w-full shadow-none variant__increment__modal__variant__add__btn__wrapper border-none"
                    // className="Productlist_product_counter_wrapper"
                  > */}
                  <input
                    type="number"
                    style={{
                      WebkitAppearance: "none",
                      margin: 0,
                      color: "var(--theme-primary)",
                      minWidth: "10px",
                      border: "none",
                      outline: "none",
                      boxShadow: "none"
                    }}
                    readOnly
                    value={cartItem.variantCount}
                    className="ItemsListPage-ItemsList-Counter-input"
                  />

                  {/* </div> */}
                </div>
                <span className="cart__order__price !font-normal !text-[14px]">
                  {currencyFormatter(
                    (cartItem.product.price * cartItem.variantCount).toFixed(2),
                    storeCurrency
                  )}
                  {/* {(cartItem.product.price * cartItem.variantCount).toFixed(2)} */}
                </span>
              </div>
            </div>
          ))}
      </div>
    );
  };

  const itemsCount = (cartItem) => {
    if (Array.isArray(Object.values(cartItem)[0])) {
      // return cartItem[0].product.image
      return 1;
    } else {
      return Object.values(cartItem)[0];
    }
  };

  function randomString(length, chars) {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  //  console.log('cartdata',cartdata)
  const productWithVariant = (cartItem) => {
    if (Array.isArray(Object.values(cartItem)[0])) {
      // console.log(cartItem, "CART ITEM")
      let tempName =
        storelanguage === "English"
          ? Object.values(cartItem)[0][0].product?.en?.name
          : Object.values(cartItem)[0][0].product?.it?.name;
      // console.log("TEMP", tempName)
      Object.values(cartItem)[0].map((variantDetails) => {
        if (variantDetails?.multipleVariants) {
          tempName += ` (${
            storelanguage === "English"
              ? variantDetails.multipleVariants
                  .map((variant) => variant.name.en)
                  .join(", ")
                  .toLowerCase()
              : variantDetails.multipleVariants
                  .map((variant) => variant.name.it)
                  .join(", ")
                  .toLowerCase()
          }) x${Object.values(variantDetails)[0]}`;
        } else {
          tempName += ` (${
            storelanguage === "English"
              ? variantDetails.productVariant.name.en
              : variantDetails.productVariant.name.it
          }) x${Object.values(variantDetails)[0]}`;
        }

        return null;
      });

      // console.log("FINAL NAME", tempName)
      return tempName;
    } else {
      return storelanguage === "English"
        ? cartItem?.product?.en?.name
        : cartItem?.product?.it?.name;
    }
  };

  const placeOrder = () => {
    if (
      (cartdata.state.message === parseInt(otp) ||
        parseInt(otp) === parseInt(localStorage.getItem("otp"))) &&
      validateOTP
    ) {
      let totalprice = totalPrice;
      let deliveryCharge = cartdata.state.deliveryCharge;
      let totaltax = cartdata.state.totaltax;
      let statetwo = cartdata?.state?.statetwo;
      let deliveryGate = localStorage.getItem("deliveryLocation");

      // let filteredCart = cart
      //   .filter(
      //     (cartObj, index) =>
      //       cart.findIndex(
      //         (item) => Object.keys(item)[0] === Object.keys(cartObj)[0]
      //       ) === index
      //   )
      //   ?.map((cartItem) => {
      //     if(!Array.isArray(Object.values(cartItem)[0])) {
      //       return cartItem;
      //     } else {
      //       let filteredCartItems = Object.values(cart.reduce((newCartItem, oldCartItem) => {
      //         if (
      //           Array.isArray(Object.values(oldCartItem)[0]) &&
      //           Object.keys(cartItem)[0] === Object.keys(oldCartItem)[0]
      //         ) {
      //           newCartItem.push(Object.values(oldCartItem)[0][0]);
      //         }
      //         return newCartItem;
      //       }, []).reduce((uniqueVariant, variant) => {
      //         if (!uniqueVariant[Object.keys(variant)[0]]) {
      //           let uniqueVariantObject = Object.keys(variant).includes("multipleVariants") ? {
      //             variantCount: 1,
      //             product: Object.values(variant)[1],
      //             productVariant: Object.values(variant)[2],
      //             multipleVariants: Object.values(variant)[3],
      //           } : {
      //             variantCount: 1,
      //             product: Object.values(variant)[1],
      //             productVariant: Object.values(variant)[2],
      //           };
      //           uniqueVariant[Object.keys(variant)[0]] = uniqueVariantObject;
      //         } else if (uniqueVariant[Object.keys(variant)[0]]) {
      //           uniqueVariant[Object.keys(variant)[0]].variantCount += 1;
      //         }

      //         return uniqueVariant;
      //       }, {}));

      //       console.log(filteredCartItems, "filteredCartItems")
      //       return filteredCartItems;
      //     }

      //   });

      // console.log(filteredCart, "filteredCart")

      cart?.map((cartItem) => {
        productsData?.push({
          name: productWithVariant(cartItem),
          description: Array.isArray(Object.values(cartItem)[0])
            ? storelanguage === "English"
              ? Object.values(cartItem)[0][0].product?.en?.description
              : Object.values(cartItem)[0][0].product?.it?.description
            : storelanguage === "English"
            ? cartItem.product?.en?.description
            : cartItem.product?.it?.description,
          productId: Array.isArray(Object.values(cartItem)[0])
            ? Object.values(cartItem)[0][0].product?._id
            : cartItem.product?._id,
          quantity: Array.isArray(Object.values(cartItem)[0])
            ? itemsCount(cartItem)
            : itemsCount(cartItem),
          price: Array.isArray(Object.values(cartItem)[0])
            ? Object.values(cartItem)[0][0].product?.price
            : cartItem.product?.price,
          size: Array.isArray(Object.values(cartItem)[0])
            ? Object.values(cartItem)[0][0].product?.size
            : cartItem.product?.size,
        });
        return null;
      });

      let tempProductData = JSON.parse(JSON.stringify(productsData));

      productsData = Object.values(
        tempProductData.reduce((newProductData, oldProductData) => {
          if (!newProductData[`${oldProductData.name}`]) {
            newProductData[`${oldProductData.name}`] = {
              ...oldProductData,
              quantity: oldProductData.quantity,
            };
          } else {
            newProductData[`${oldProductData.name}`].quantity += 1;
          }
          return newProductData;
        }, {})
      );

      // productsData = Object.values(tempProductData)
      // console.log(tempProductData, "tempproductsData");
      // console.log(productsData, "productsData");

      let checkoutData = cartdata?.state?.cartData;
      let sendData = cartdata.state;

      getstorelist()
        .then((res) => {
          res.data.message.map((item) => {
            if (
              item.storeId === cartdata?.state?.storeId ||
              cartdata?.state?.statetwo?.storeId === item.storeId
            ) {
              if (item.status === 0 || !Etaservice) {
                handleOpen();
              } else if (item.status === 1) {
                if (storetype === "hms") {
                  orderbookingtwo(randomOrderId, sendData)
                    .then((res) => {
                      // console.log('Order Booking Response', res)
                      if (
                        res.data.status === 200 ||
                        res.data.status === "200"
                      ) {
                        navigate("/redirecting", {
                          state: { randomOrderId, sendData },
                        });
                      }
                    })
                    .catch((err) => {
                      console.error("Error in Order Booking", err);
                      Bookingerror();
                    });
                } else {
                  orderbooking(
                    randomOrderId,
                    cartdata.state,
                    productsData,
                    totalPrice,
                    deliveryCharge,
                    cartdata.state.statetwo.pickup,
                    cartdata.state.locationName,
                    totaltax,
                    storeCurrency.symbol,
                    cart,
                    storelanguage,
                    availableDiscount
                  )
                    .then((res) => {
                      // console.log('Order Booking Response', res)
                      if (
                        res.data.status === 200 ||
                        res.data.status === "200"
                      ) {
                        // sessionStorage.setItem("deliveryLocation", null);
                        if (
                          availableDiscount == null ||
                          parseFloat(
                            availableDiscount?.totalAfterDiscount
                          ).toFixed(2) > 0
                        ) {
                          navigate("/checkout", {
                            state: {
                              checkoutData,
                              cartdata,
                              statetwo,
                              orderId: randomOrderId,
                              productsData: productsData,
                              totalprice: totalPrice,
                              availableDiscount: availableDiscount,
                              deliveryCharge: deliveryCharge,
                              deliveryGate: deliveryGate,
                              totaltax: totaltax,
                              pin: randomOrderId.substr(-4),
                            },
                          });
                        } else {
                          let cartData = {
                            state: {
                              checkoutData,
                              cartdata,
                              statetwo,
                              orderId: randomOrderId,
                              productsData: productsData,
                              totalprice: totalPrice,
                              availableDiscount: availableDiscount,
                              deliveryCharge: deliveryCharge,
                              deliveryGate: deliveryGate,
                              totaltax: totaltax,
                              pin: randomOrderId.substr(-4),
                            },
                          };
                          orderconfirm(
                            cartData,
                            "Discount",
                            "paid-by-discount/wallet",
                            cart,
                            totalPrice,
                            storelanguage
                          )
                            .then((res) => {
                              toast.success(res.data.message);
                              // console.log("Order Confirm Success", res.data)
                              navigate("/congratulations", { state: cartData });
                              dispatch(clearCart());
                              localStorage.clear();
                              sessionStorage.clear();
                            })
                            .catch((err) => {
                              toast.error(err.data.message);
                              console.error("Order Confirm Error", err.data);
                            });
                        }
                      }
                    })
                    .catch((err) => {
                      console.error("Error in Order Booking", err);
                      Bookingerror();
                    });
                }
              }
            }
            return null;
          });
        })
        .catch((err) => {});
    }
  };

  const verifyOTP = () => {
    // console.log('sfvsfff')
    let resendotp = localStorage.getItem("otp");
    // let message = "Hello there! Your order has been placed successfully. Thanks for placing an order!"
    if (
      cartdata.state.message === parseInt(otp) ||
      parseInt(otp) === parseInt(resendotp)
    ) {
      let randomorderId = randomString(6, "0123456789");
      // let totalprice = totalPrice;
      let deliveryCharge = cartdata.state.deliveryCharge;
      let totaltax = cartdata.state.totaltax;

      setRandomOrderId(randomorderId);
      otpSuccess();
      setValidateOTP(true);

      let cartProducts = cart?.map((cartItem) => {
        return productsData?.push({
          name: productWithVariant(cartItem),
          description: Array.isArray(Object.values(cartItem)[0])
            ? storelanguage === "English"
              ? Object.values(cartItem)[0][0].product?.en?.description
              : Object.values(cartItem)[0][0].product?.it?.description
            : storelanguage === "English"
            ? cartItem.product?.en?.description
            : cartItem.product?.it?.description,
          productId: Array.isArray(Object.values(cartItem)[0])
            ? Object.values(cartItem)[0][0].product?._id
            : cartItem.product?._id,
          quantity: Array.isArray(Object.values(cartItem)[0])
            ? itemsCount(cartItem)
            : itemsCount(cartItem),
          price: Array.isArray(Object.values(cartItem)[0])
            ? Object.values(cartItem)[0][0].product?.price
            : cartItem.product?.price,
          size: Array.isArray(Object.values(cartItem)[0])
            ? Object.values(cartItem)[0][0].product?.size
            : cartItem.product?.size,
        });
      });

      checkDiscountAvailability({
        code: discountDetails?.code,
        storeId: cartdata?.state.statetwo.storeId,
        orderId: randomorderId,
        products: cartProducts,
        total:
          totalPrice +
          parseFloat(cartdata?.state?.deliveryCharge) +
          cartdata?.state?.totaltax,
        cartTotal: totalPrice,
        tax: cartdata?.state?.totaltax,
        customer: cartdata?.state.username,
        phone: cartdata?.state?.contactNo,
      }).then((res) => {
        if (res.data.status === 200) {
          console.log(res, "Check Discount Availability");
          setAvailableDiscount(res.data.data);
          setDisablePlaceOrder(false);
        } else {
          setDiscountMessage("No Discount Available");
          setDisablePlaceOrder(false);
        }
      });
    } else {
      // console.log('Enter Valid OTP')
      otpError();
      // console.log('resendotp', resendotp)
    }
  };

  const dispatch = useDispatch();

  const { t } = useTranslation();
  // console.log('cartdatacartdatacartdata',cartdata)

  const handleresendOtp = () => {
    let messageotp = Math.floor(100000 + Math.random() * 900000);
    localStorage.setItem("otp", messageotp);
    cartdata.state.message = "";
    sendMessage(parseInt(cartdata.state?.contactNo), messageotp, {
      customerName: cartdata.state?.username,
      language: storelanguage,
    })
      .then((res) => {
        // console.log("OTP Send Success", res);
        if (res.data.status === 200 || res.data.status === "200") {
          otpresend();
        }
      })
      .catch((err) => {
        console.error("Error in send OTP", err);
        otpError();
      });
  };

  const handlecartedit = () => {
    navigate(`/storelist/${cartdata?.state?.statetwo?._id}`, {
      state: { item: cartdata?.state?.statetwo },
    });
  };

  useEffect(() => {
    setDeliveryCharge(cartdata?.state?.statetwo?.deliveryfee);
    // let totalpricearr = [];
    // Data?.state?.stateone.map((item) => {
    //   totalpricearr.push(item.price * item.count);
    //   return null;
    // });
  }, []);

  useEffect(() => {
    if (
      !cartdata?.state ||
      cartdata?.state === undefined ||
      cartdata?.state === null ||
      cartdata?.state === "null"
    ) {
      window.location.replace("/");
    }
  }, [cartdata?.state]);
  return (
    <>
      <div className="MainWhole-wrapper-container">
        <Header arrow={false} />
        {/* {console.log('cartdatacartdata',cartdata)} */}
        <div className="CartPage-Main-wrapper">
          {!validateOTP ? (
            <>
              <h2 className="!text-[16px]">
                {storelanguage === "English"
                  ? "Enter the verification code"
                  : "Introduzca el código de verificación"}
              </h2>
              {storelanguage === "English" ? (
                <h5 className="CartPage-Main-wrapper_h5 !text-sm">
                  {/* {t('cart_page_sub_heading')} */}
                  you have received in your mobile phone <br />{" "}
                  <b>*** ***{cartdata.state?.contactNo.substr(-5)}</b>
                </h5>
              ) : (
                <h5 className="CartPage-Main-wrapper_h5">
                  {/* {t('cart_page_sub_heading')} */}
                  que ha recibido en su teléfono móvil
                  <br /> <b>*** ***{cartdata.state?.contactNo.substr(-5)}</b>
                </h5>
              )}
              {/* {console.log('cartdatacartdata',cartdata)} */}

              <div className="CartPage-content-wrapper">
                <div className="DetailsPage-content-wrapper-inner">
                  {/* <InputLabel className='detailspage-content-label'> {t('cart_page_Enter_otp')} <sup style={{color:"red"}}>*</sup></InputLabel> */}
                  <OTPInput
                    value={otp}
                    placeorder="*"
                    onChange={setOtp}
                    autoFocus
                    OTPLength={6}
                    otpType="number"
                    disabled={false}
                    className="Cart_page_otp_input"
                  />
                </div>
                <div className="Cart_page_Resend_button_wrapper">
                  {storelanguage === "English" ? (
                    <p className="!text-[14px] !m-0"> Didn't get the code? </p>
                  ) : (
                    <p className="!text-[14px] !m-0">
                      {" "}
                      ¿No recibiste el código?{" "}
                    </p>
                  )}
                  <button
                    onClick={() => handleresendOtp()}
                    className="!text-[14px]"
                  >
                    {" "}
                    {storelanguage === "English"
                      ? "Resend Code"
                      : "Reenviar codigo"}{" "}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <h2 className="text-center !text-[18px] !text-[var(--theme-primary)]">
                {storelanguage === "English"
                  ? "Code Verified Successfully!"
                  : "¡Código verificado exitosamente!"}
              </h2>
            </>
          )}

          {/* <div className="DetailsPage-content-wrapper">
               <h2 className="CartPage-Product-wrapper-h2">
               {t('cart_page_View_Cart')}

               </h2>
                  {cartdata?.state?.cartData?.map((item)=>{
                    if(item.count>0){
                     return (  <div className="ItemsListPage-ItemsList-containers">       
                     <div className='product-content-wrapper'> 
                                     <div className='product-content-wrapper-inner-div-one'> 
                                     <img src={item.image} className='product-images-class'/>
                                     </div>
                                     <div className='product-content-wrapper-inner-div-two'>
                                         <h4> {item.title}</h4>
                                         <p>€{item.price}</p>
                                     </div>
                     </div>
                 <div className='ItemsListPage-ItemsList-Counter-wrapper'> 
                   <button className='ItemsListPage-ItemsList-Counter-btn' onClick={()=>{ dispatch(DecrementCount(item, item.id))}
                 }> -</button>
                   <input type="number" readOnly value={item.count} className="ItemsListPage-ItemsList-Counter-input"/> 
                   <button className='ItemsListPage-ItemsList-Counter-btn' onClick={()=>{ dispatch(IncrementCount(item, item.id))}}> +</button>  
                 </div>
              </div>)}
                  })}  
            </div>  */}
          <div className="BillDetails_page_BillTotal_wrapper">
            {storetype === "self" && (
              <div className="d_flex_center">
                <h3 className="!text-black !text-[18px]">
                  {" "}
                  {storelanguage === "English" ? "Cart" : "Detalles del pedido"}
                </h3>
              </div>
            )}
            <div className="DetailsPage-content-wrapper">
              {cart && cart.length > 0 && (
                <>
                  {cart
                    .filter(
                      (cartObj, index) =>
                        cart.findIndex(
                          (item) =>
                            Object.keys(item)[0] === Object.keys(cartObj)[0]
                        ) === index
                    )
                    .map((cartItem, index) => {
                      return (
                        <Fragment key={"cart__item__no__" + index}>
                          {!Array.isArray(Object.values(cartItem)[0]) ? (
                            <div
                              key={"cartItem" + index}
                              className={`mb-2 cart__item__list__container ${
                                cart.length === 1 &&
                                "border-b-[0.25rem] border-b-[var(--theme-primary)] !mb-0 pb-[12px]"
                              }`}
                            >
                              <>
                                <div className="h-full product-content-wrapper">
                                  {/* <span
                                className="cart__order__delete"
                                onClick={() =>
                                  deleteCartItem(cartItem.product, cartItem)
                                }
                              >
                                <RxCross2 className="cart__order__delete__icon" />
                              </span> */}
                                  {/* <div className="product-content-wrapper-inner-div-one">
                                <img
                                  src={
                                    Array.isArray(Object.values(cartItem)[0])
                                      ? Object.values(cartItem)[0][0].product
                                          .image
                                      : cartItem.product?.image
                                  }
                                  className="product-images-class"
                                  alt=""
                                />
                              </div> */}
                                  <div className="product-content-wrapper-inner-div-two">
                                    <h4 className="!text-[14px] !leading-5 !line-clamp-2">
                                      {" "}
                                      {storelanguage === "English" ? (
                                        <>
                                          {Array.isArray(
                                            Object.values(cartItem)[0]
                                          )
                                            ? `${
                                                Object.values(cartItem)[0][0]
                                                  .product?.en?.name
                                              } - ${
                                                Object.values(cartItem)[0][0]
                                                  .productVariant?.name?.en
                                              }`
                                            : cartItem.product?.en?.name}
                                        </>
                                      ) : (
                                        <>
                                          {Array.isArray(
                                            Object.values(cartItem)[0]
                                          )
                                            ? `${
                                                Object.values(cartItem)[0][0]
                                                  .product?.it?.name
                                              } - ${
                                                Object.values(cartItem)[0][0]
                                                  .productVariant?.name?.it
                                              }`
                                            : cartItem.product?.it?.name}
                                        </>
                                      )}{" "}
                                    </h4>
                                    {/* <span className="Bill_Details_content_itemcount"> */}
                                    {/* x {cartItem.count} */}
                                    {/* {variantCount(cartItem)} */}
                                    {/* </span> */}
                                    {/* <div className='Bill_Details_content_itemcount'> <h3>  </h3></div>  */}
                                  </div>
                                </div>
                                <div className="cart__product__list__container">
                                  <div className="cart__product__list__wrapper !border-none !shadow-none">
                                    {cart && (
                                      <>
                                        <input
                                          type="number"
                                          style={{
                                            WebkitAppearance: "none",
                                            margin: 0,
                                            color: "var(--theme-primary)",
                                            minWidth: "20px",
                                            border: "none",
                                            outline: "none",
                                            boxShadow: "none"
                                          }}
                                          readOnly
                                          // value={calculateQuantity(cartItem.product, cart)}
                                          value={variantCount(cartItem)}
                                          className="ItemsListPage-ItemsList-Counter-input"
                                        />
                                      </>
                                    )}
                                  </div>
                                  <span className="cart__order__price !font-normal !text-[14px]">
                                    {Array.isArray(Object.values(cartItem)[0])
                                      ? currencyFormatter(
                                          Object.values(
                                            cartItem
                                          )[0][0]?.product?.price.toFixed(2),
                                          storeCurrency
                                        )
                                      : currencyFormatter(
                                          (
                                            cartItem.product?.price *
                                            itemsCount(cartItem)
                                          ).toFixed(2),
                                          storeCurrency
                                        )}
                                  </span>
                                </div>
                              </>
                            </div>
                          ) : (
                            <div
                              key={"cartItem" + index}
                              className={`px-[10px] ${
                                index !==
                                cart.filter(
                                  (cartObj, index) =>
                                    cart.findIndex(
                                      (item) =>
                                        Object.keys(item)[0] ===
                                        Object.keys(cartObj)[0]
                                    ) === index
                                ).length -
                                  1
                                  ? "border-b-[#ddd] border-b-[2px] border-solid pb-[12px] mb-2"
                                  : "border-b-[var(--theme-primary)] border-b-[0.25rem] border-solid pb-[12px] mb-2"
                              } items-center flex ${
                                index !== 0 ? "pt-[1rem]" : ""
                              }`}
                            >
                              <div className="h-full product-content-wrapper">
                                <div className="gap-2 product-content-wrapper-inner-div-two">
                                  <h4 className="!text-[14px] !leading-5 !line-clamp-2">
                                    {storelanguage === "English" ? (
                                      <>
                                        {
                                          Object.values(cartItem)[0][0].product
                                            ?.en?.name
                                        }
                                      </>
                                    ) : (
                                      <>
                                        {
                                          Object.values(cartItem)[0][0].product
                                            ?.it?.name
                                        }
                                      </>
                                    )}
                                  </h4>
                                  <div className="mb-0 variant__increment__modal__variant__details__wrapper">
                                    <div className="variant__increment__modal__variant__wrapper">
                                      {cart && (
                                        <CalculateVariantsQuantity
                                          key={
                                            "calculate__variant__quantity__" +
                                            index
                                          }
                                          cartItems={cart.reduce(
                                            (newCartItem, oldCartItem) => {
                                              if (
                                                Array.isArray(
                                                  Object.values(oldCartItem)[0]
                                                ) &&
                                                Object.keys(cartItem)[0] ===
                                                  Object.keys(oldCartItem)[0]
                                              ) {
                                                newCartItem.push(
                                                  Object.values(
                                                    oldCartItem
                                                  )[0][0]
                                                );
                                              }
                                              return newCartItem;
                                            },
                                            []
                                          )}
                                          // setIncrementVariantsModal={setIncrementVariantsModal}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {/* {index !==
                              cart.filter(
                                (cartObj, index) =>
                                  cart.findIndex(
                                    (item) =>
                                      Object.keys(item)[0] ===
                                      Object.keys(cartObj)[0]
                                  ) === index
                              ).length -
                                1 && (
                              <hr className="m-0 border-solid border-b border-b-[rgba(0,0,0,1)] opacity-30" />
                            )} */}
                        </Fragment>
                      );
                    })}
                </>
              )}
              {/* <div className="mb-2 cart__item__list__container">
                  Selected Loc
                </div> */}
              <div className="BillDetails_item_topay_wrapper">
                <div className="self-center text_align_start">
                  {" "}
                  <p className="!text-[14px] !text-black">
                    {" "}
                    {storelanguage === "English"
                      ? "Selected Location:"
                      : "Punto de recogida seleccionado:"}{" "}
                  </p>
                </div>
                <div className="flex flex-col text_align_end">
                  {" "}
                  <span className="!font-normal !text-[14px] !text-black">
                    {storelanguage === "English"
                      ? cartdata.state?.locationName?.group.split("_").join(" ")
                      : cartdata.state?.locationName?.group
                          .split("_")
                          .join(" ")}
                  </span>
                  <span className="!font-bold !text-[14px] !text-[var(--theme-primary)] !capitalize">
                    {storelanguage === "English"
                      ? cartdata.state?.locationName?.locationName
                          .split("_")
                          .join(" ")
                          .toLowerCase()
                      : cartdata.state?.locationName?.spanishLocationName
                          .split("_")
                          .join(" ")
                          .toLowerCase()}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {availableDiscount ? (
            <div className="discount__container">
              <span className="text-sm font-bold text-left text-[var(--theme-primary)]">
                {availableDiscount?.discountCode}
                {" : "}
                {storelanguage === "English"
                  ? availableDiscount.description?.en
                  : availableDiscount.description?.it}
              </span>
              <div className="flex items-center justify-end w-full">
                <span className="text-xs font-bold text-[var(--theme-primary)] underline">
                  {storelanguage === "English" ? "Applied" : "Aplicado"}
                </span>
              </div>
            </div>
          ) : (
            <>
              {discountMessage && (
                <div className="discount__container">
                  <span className="text-sm font-bold text-left text-red-600">
                    {storelanguage === "English"
                      ? "No Discount Available"
                      : "No hay descuento disponible"}
                  </span>
                </div>
              )}
            </>
          )}
          <div className="Billpage_proceed_tonext_btn_wrapper">
            {availableDiscount && availableDiscount?.discountCode ? (
              <div className="flex items-center gap-2">
                <h3 className="!font-light text-white line-through text-[22px]">
                  {" "}
                  {currencyFormatter(
                    (totalPrice + deliveryCharge).toFixed(2),
                    storeCurrency
                  )}
                </h3>
                <span className="text-[22px] font-bold text-white">
                  {" "}
                  {currencyFormatter(
                    (availableDiscount?.totalAfterDiscount).toFixed(2),
                    storeCurrency
                  )}
                </span>
              </div>
            ) : (
              <h3 className="!text-white text-[22px]">
                {" "}
                {currencyFormatter(
                  (totalPrice + deliveryCharge).toFixed(2),
                  storeCurrency
                )}
              </h3>
            )}

            {!validateOTP ? (
              <>
                {!Etaservice ? (
                  <button
                    type="submit"
                    className="BillPage_proceed_to_btn !text-[16px] !bg-[rgba(255,255,255,0.7)] !cursor-not-allowed"
                    disabled={true}
                    // onClick={() => verifyOTP()}
                  >
                    {" "}
                    {storelanguage === "English"
                      ? "Verify Code"
                      : "Código de verificación"}{" "}
                    <span> </span>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="BillPage_proceed_to_btn !text-[16px] cursor-pointer"
                    onClick={() => verifyOTP()}
                  >
                    {" "}
                    {storelanguage === "English"
                      ? "Verify Code"
                      : "Código de verificación"}{" "}
                    <span> </span>
                  </button>
                )}
              </>
            ) : (
              <>
                {!Etaservice ? (
                  <button
                    type="submit"
                    className="BillPage_proceed_to_btn !bg-[rgba(255,255,255,0.7)] !cursor-not-allowed"
                    disabled={true}
                    // onClick={() => placeOrder()}
                  >
                    {" "}
                    {storelanguage === "English"
                      ? "Place Order"
                      : "Proceder al pago"}{" "}
                    <span> </span>
                  </button>
                ) : (
                  <>
                    {disablePlaceOrder ? (
                      <button
                        type="submit"
                        className="BillPage_proceed_to_btn !bg-[rgba(255,255,255,0.7)] !cursor-not-allowed"
                        disabled={true}
                      >
                        {" "}
                        {storelanguage === "English"
                          ? "Place Order"
                          : "Proceder al pago"}{" "}
                        <span> </span>
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="BillPage_proceed_to_btn !cursor-pointer"
                        onClick={() => placeOrder()}
                      >
                        {" "}
                        {storelanguage === "English"
                          ? "Place Order"
                          : "Proceder al pago"}{" "}
                        <span> </span>
                      </button>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        {/* <div
          className="Congratulations_page_estimated_time_div_grey"
          id="Estimated_time"
        >
          {Etaservice ? (
            <p>
              {" "}
              {storelanguage === "English"
                ? "Estimated Delivery Time"
                : "Tempo di consegna stimato"}{" "}
            </p>
          ) : (
            <>{Etatime}</>
          )}
          {Etaservice ? <h3>{Etatime ? Etatime : 0} </h3> : null}
        </div> */}
      </div>
      <Modal
        open={open}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="Sorry_div_wrapper">
            <div className="Sorry_div_wrapper_inner_div" onClick={handleClose}>
              <HighlightOffIcon style={{ fontSize: "35px", margin: "5px" }} />
              <p style={{ fontSize: "17px" }}>
                {storelanguage === "English" ? "Close" : "Cerca"}{" "}
              </p>
            </div>
            <img src="/assets/images/Emoji_logo.png" alt="" />
            <h4>
              {storelanguage === "English" ? "Sorry !!!" : "Lo siento !!!"}
            </h4>
            <p className="Sorry_div_wrapper_p">
              {" "}
              {storelanguage === "English"
                ? "Store is closed, please try again later"
                : "La tienda está cerrada, inténtalo de nuevo más tarde"}{" "}
            </p>
          </div>
        </Box>
      </Modal>
      <Footer />
    </>
  );
}

export default Cart;
