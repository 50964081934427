import React from "react";
import { useNavigate } from "react-router-dom";

function HeaderRedirecting() {
  const navigate = useNavigate();
  return (
    <div className="Redirecting_header_wrapper">
      <img
        src="/assets/images/sodexo__logo.png"
        onClick={() => navigate("/")}
        style={{ cursor: "pointer" }}
        alt=""
      />
    </div>
  );
}

export default HeaderRedirecting;
