import {
  BottomNavigation,
  BottomNavigationAction,
  InputLabel,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment } from "react";
import Header from "./Header";
import { Form, Formik, useFormik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import { getlocationlist, sendMessage } from "../API";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Footer from "./Footer";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { currencyFormatter } from "../utils";
import { Link } from "react-router-dom";
import { DecrementCount, IncrementCount } from "../redux/Actions";

function Details() {
  const [btndisable, setbtndisable] = useState(false);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [discountBannerValue, setDiscountBannerValue] = useState(
    process.env.REACT_APP_MIN_CART_VALUE
  );

  const dispatch = useDispatch();
  const { cart, totalitems, totalPrice } = useSelector((state) => state.IC);

  // if (
  //   !Array.isArray(cart) &&
  //   typeof cart === "object" &&
  //   localStorage.getItem("persist:root")
  // ) {
  //   localStorage.setItem(
  //     "persist:root",
  //     '{"IC":"{\\"cart\\":[],\\"totalitems\\":0,\\"totalPrice\\":0}","ItemsListReducer":"[]","otpNumberReducer":"[]","paymentReducer":"{\\"Payment_refrence\\":{}}","storeCurrencyReducer":"{}","storelanguagereducer":"{\\"storelanguage\\":\\"English\\"}","storemapIdreducer":"{}","_persist":"{\\"version\\":-1,\\"rehydrated\\":true}"}'
  //   );
  //   window.location.reload(false);
  // }

  const Data = useLocation();
  const { storeCurrency } = useSelector((state) => state.storeCurrencyReducer);
  const { storelanguage } = useSelector((state) => state.storelanguagereducer);
  const cartData = Data.state?.cartdata;
  const locationName = Data.state?.locationName;
  const storeId = Data.state?.storeId;
  const navigate = useNavigate();
  const otpError = () => {
    storelanguage === "English"
      ? toast.error("OTP send Failed")
      : toast.error("Error de envío OTP");
  };
  const otpSuccess = () =>
    storelanguage === "English"
      ? toast.success("OTP send successfully")
      : toast.success("Envío OTP exitoso");
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = yup.object({
    contactno: yup
      .string()
      .matches(
        phoneRegExp,
        `${
          storelanguage === "English"
            ? "Phone number is not valid"
            : "El número de teléfono no es válido"
        }`
      )
      .required(
        `${
          storelanguage === "English"
            ? "Phone Number is required"
            : "Se requiere número de teléfono"
        }`
      ),
    username: yup
      .string()
      .required(
        `${
          storelanguage === "English"
            ? "User Name is required"
            : "Se requiere nombre de usuario"
        }`
      ),
    agree: yup
      .string()
      .required(
        `${
          storelanguage === "English"
            ? "Please select this"
            : "Por favor seleccione esto"
        }`
      ),
  });
  const storetype = localStorage.getItem("store_type");
  // console.log('Data.state.storeId',Data.state.storeId)
  const formik = useFormik({
    initialValues: {
      contactno: process.env.REACT_APP_PHONE_NUMBER_PREFIX,
      username: "",
      agree: true,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (cart.length === 0 || parseFloat(totalPrice) === 0) {
        if (storelanguage === "English") {
          toast.error("Your Cart is Empty.");
        } else {
          toast.error("Tu carrito esta vacío.");
        }
      } else {
        // console.log(discountBannerValue,"discountBannerValue")
        if (discountBannerValue && totalPrice < discountBannerValue) {
          toast.error(
            `Minimum order amount is ${currencyFormatter(
              discountBannerValue,
              storeCurrency
            )}. Please add more items.`
          );
          return;
        }
        //  console.log('values',values)
        let message = Math.floor(100000 + Math.random() * 900000);
        sendMessage(values.contactno, message, {
          customerName: values.username,
          language: storelanguage,
        })
          .then((res) => {
            // console.log('OTP Send Success' ,res)
            if (res.data.status === 200 || res.data.status === "200") {
              let contactNo = values.contactno;
              let username = values.username;
              otpSuccess();
              navigate("/cart", {
                state: {
                  cartData,
                  message,
                  locationName: locationName,
                  contactNo,
                  username,
                  statetwo: Data?.state?.statetwo,
                  totalPrice: Data?.state?.totalPrice,
                  deliveryCharge: Data?.state?.deliveryCharge,
                  totaltax: Data?.state?.totaltax,
                  storeId,
                },
              });
              setbtndisable(true);
            }
          })
          .catch((err) => {
            console.error("Error in send OTP", err);
            otpError();
          });
      }
    },
  });
  const onValueChange = (value) => {
    formik.setFieldValue("contactno", value);
  };
  const handlecartedit = () => {
    navigate(`/storelist/${Data?.state?.statetwo?._id}`, {
      state: { item: Data?.state?.statetwo },
    });
  };
  // console.log('Data',Data)
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleagreechange = (e) => {
    if (e.target.checked) {
      formik.setFieldValue("agree", e.target.checked);
    } else {
      formik.setFieldValue("agree", "");
    }
  };

  const variantCount = (cartItem) => {
    if (Array.isArray(Object.values(cartItem)[0])) {
      let quantityCount = Object.values(cartItem)[0].map(
        (variantItem, index) => {
          return 1;
        }
      );
      return quantityCount;
    } else {
      return `${Object.values(cartItem)[0]}`;
    }
  };

  const CalculateVariantsQuantity = ({ cartItems }) => {
    let filteredCartItems = cartItems.reduce((uniqueVariant, variant) => {
      if (!uniqueVariant[Object.keys(variant)[0]]) {
        let uniqueVariantObject = Object.keys(variant).includes(
          "multipleVariants"
        )
          ? {
              variantCount: 1,
              product: Object.values(variant)[1],
              productVariant: Object.values(variant)[2],
              multipleVariants: Object.values(variant)[3],
            }
          : {
              variantCount: 1,
              product: Object.values(variant)[1],
              productVariant: Object.values(variant)[2],
            };
        uniqueVariant[Object.keys(variant)[0]] = uniqueVariantObject;
      } else if (uniqueVariant[Object.keys(variant)[0]]) {
        uniqueVariant[Object.keys(variant)[0]].variantCount += 1;
      }

      return uniqueVariant;
    }, []);

    return (
      <div className="flex flex-col gap-2">
        {Object.values(filteredCartItems).length > 0 &&
          Object.values(filteredCartItems).map((cartItem, index) => (
            <div
              key={"filtered__cart__item__no__" + index}
              // className="flex flex-col gap-1"
              className="grid grid-cols-[50%_50%] items-center"
            >
              <span className="text-[0.9rem] font-medium capitalize">
                {cartItem?.multipleVariants?.length > 0 ? (
                  <>
                    {storelanguage === "English"
                      ? `${cartItem.multipleVariants
                          .map((variant) => variant.name.en)
                          .join(", ")
                          .toLowerCase()}`
                      : `${cartItem.multipleVariants
                          .map((variant) => variant.name.it)
                          .join(", ")
                          .toLowerCase()}`}
                  </>
                ) : (
                  <>
                    {storelanguage === "English"
                      ? `${cartItem.productVariant?.name?.en?.toLowerCase()}`
                      : `${cartItem.productVariant?.name?.it?.toLowerCase()}`}
                  </>
                )}
              </span>
              <div className="cart__product__list__container">
                <div className="cart__product__list__wrapper">
                  {/* <div
                    key={"variant__increment__modal__variant__div__" + index}
                    className="!w-full shadow-none variant__increment__modal__variant__add__btn__wrapper border-none"
                    // className="Productlist_product_counter_wrapper"
                  > */}
                  <button
                    className="ItemsListPage-ItemsList-Counter-btn !py-[5px]"
                    onClick={() => {
                      dispatch(
                        DecrementCount(
                          cartItem.product,
                          cart,
                          totalPrice,
                          cartItem.product?.variant?.length !== 0
                            ? {
                                product: cartItem.product,
                                variantProduct: cartItem.productVariant,
                                multipleVariants:
                                  cartItem?.multipleVariants?.length > 0
                                    ? cartItem?.multipleVariants
                                    : undefined,
                              }
                            : undefined
                        )
                      );
                    }}
                  >
                    {" "}
                    -{" "}
                  </button>
                  <input
                    type="number"
                    style={{
                      WebkitAppearance: "none",
                      margin: 0,
                      color: "var(--theme-primary)",
                      minWidth: "10px",
                    }}
                    readOnly
                    value={cartItem.variantCount}
                    className="ItemsListPage-ItemsList-Counter-input"
                  />
                  <button
                    className="ItemsListPage-ItemsList-Counter-btn !py-[5px]"
                    onClick={() => {
                      dispatch(
                        IncrementCount(
                          cartItem.product,
                          cart,
                          totalPrice,
                          cartItem.product?.variant?.length !== 0
                            ? {
                                product: cartItem.product,
                                variantProduct: cartItem.productVariant,
                                multipleVariants:
                                  cartItem?.multipleVariants?.length > 0
                                    ? cartItem?.multipleVariants
                                    : undefined,
                              }
                            : undefined
                        )
                      );
                    }}
                  >
                    {" "}
                    +{" "}
                  </button>{" "}
                  {/* </div> */}
                </div>
                <span className="cart__order__price !font-normal !text-[14px]">
                  {currencyFormatter(
                    (cartItem.product.price * cartItem.variantCount).toFixed(2),
                    storeCurrency
                  )}
                  {/* {(cartItem.product.price * cartItem.variantCount).toFixed(2)} */}
                </span>
              </div>
            </div>
          ))}
      </div>
    );
  };

  const itemsCount = (cartItem) => {
    if (Array.isArray(Object.values(cartItem)[0])) {
      // return cartItem[0].product.image
      return 1;
    } else {
      return Object.values(cartItem)[0];
    }
  };

  useEffect(() => {
    setDeliveryCharge(Data?.state?.statetwo?.deliveryfee);
    // let totalpricearr = [];
    // Data?.state?.stateone.map((item) => {
    //   totalpricearr.push(item.price * item.count);
    //   return null;
    // });
  }, []);

  useEffect(() => {
    if (
      !Data?.state ||
      Data?.state === undefined ||
      Data?.state === null ||
      Data?.state === "null"
    ) {
      window.location.replace("/");
    }
  }, [Data?.state]);
  return (
    <>
      <div className="MainWhole-wrapper-container">
        <Header arrow={false} />
        {/* {console.log('DataData',Data)} */}
        <div className="DetailsPage-Main-wrapper">
          {/* <h2> {t('cart_page_heading')}</h2> */}
          <span className="text-[18px] font-bold">
            {storelanguage === "English"
              ? "Enter your details"
              : "Introduce tus detalles*"}
          </span>
          <h2> </h2>
          <form
            onSubmit={formik.handleSubmit}
            // style={{height:'100vh'}}
          >
            <div className="DetailsPage-content-wrapper !px-[20px]">
              <div className="DetailsPage-content-wrapper-inner">
                {/* <InputLabel style={{fontFamily:"'Poppins', sans-serif"}} className='detailspage-content-label'> {t('cart_page_phone_number')} <sup style={{color:"red"}}>*</sup></InputLabel> */}
                <h3 className="DetailsPage-content-wrapper-inner_h3 !text-[14px]">
                  {storelanguage === "English"
                    ? "Enter Phone Number*"
                    : "Número de teléfono*"}
                </h3>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    className="inline"
                    style={{ width: "100%", margin: "auto" }}
                  >
                    <PhoneInput
                      style={{ marginBottom: "10px", textAlign: "justify" }}
                      country={"us"}
                      specialLabel="Phone Number"
                      enableSearch={true}
                      autoFocus={true}
                      value={formik.values.contactno}
                      onChange={(value) => onValueChange(value)}
                      countryCodeEditable={false}
                      margin="standard"
                      name="contactno"
                      label="Phone Number"
                      required
                      containerStyle={{}}
                      inputStyle={{
                        border: "2px solid #70707087",
                        width: "100%",
                        padding: "8px 60px",
                        borderRadius: "8px",
                      }}
                      dropdownStyle={{}}
                      error={
                        formik.touched.contactno &&
                        Boolean(formik.errors.contactno)
                      }
                      // helperText={
                      //   formik.touched.contactno && formik.errors.contactno
                      // }
                    />
                  </div>
                </div>
                {formik.errors.contactno && formik.touched.contactno ? (
                  <p
                    style={{
                      color: "#d32f2f",
                      fontSize: "0.75rem",
                      marginTop: "-2px",
                      textAlign: "left",
                      paddingLeft: "0px",
                    }}
                  >
                    {storelanguage === "English"
                      ? "Please Enter Phone Number"
                      : "Por favor ingrese el número de teléfono"}
                  </p>
                ) : null}
              </div>
              <div className="DetailsPage-content-wrapper-inner Details_page_input_wrapper">
                <h3 className="DetailsPage-content-wrapper-inner_h3 !text-[14px]">
                  {storelanguage === "English" ? "Name*" : "Nombre*"}{" "}
                </h3>
                <TextField
                  style={{ marginTop: "8px", textAlign: "justify" }}
                  id="standard-size-normal"
                  fullWidth
                  variant="outlined"
                  value={formik.values.username}
                  name="username"
                  onChange={formik.handleChange}
                  placeholder="Enter the Full Name"
                  error={
                    formik.touched.username && Boolean(formik.errors.username)
                  }
                  inputProps={{
                    style: {
                      borderRadius: "8px",
                      border: "2px solid rgba(112, 112, 112, 0.53)",
                      fontSize: "14px",
                      // padding: "10px",
                    },
                  }}
                  className="customer__phone__number"
                  sx={{ backgroundColor: "white", border: "none!important" }}
                />
                {formik.errors.username && formik.touched.username ? (
                  <p
                    style={{
                      color: "#d32f2f",
                      fontSize: "0.75rem",
                      marginTop: "-2px",
                      textAlign: "left",
                      paddingLeft: "0px",
                    }}
                  >
                    {storelanguage === "English"
                      ? "User Name is required"
                      : "Se requiere nombre de usuario"}
                  </p>
                ) : null}
              </div>
              <div className="Details_page_agree_checkBox">
                <Checkbox
                  onChange={(e) => handleagreechange(e)}
                  defaultChecked={true}
                  {...label}
                  error={formik.touched.agree && Boolean(formik.errors.agree)}
                  // helperText={formik.touched.agree && formik.errors.agree}
                  style={{
                    cursor: "pointer",
                  }}
                />
                <p>
                  {storelanguage === "English"
                    ? "I Agree to receive delivery notification via SMS at the number provided above"
                    : "Acepto recibir notificación de entrega por SMS al número proporcionado arriba"}{" "}
                  {storelanguage === "English" ? "and accept" : "y aceptar"}{" "}
                  <Link
                    to={"/help"}
                    className="Help_page-content-p_two"
                    // href="mailto:support@ottonomy.io"
                  >
                    {" "}
                    {storelanguage === "English"
                      ? "Privacy Policy"
                      : "política de privacidad"}{" "}
                  </Link>{" "}
                  {storelanguage === "English" ? "and" : "y"}{" "}
                  <Link
                    to={"/help"}
                    className="Help_page-content-p_two"
                    // href="mailto:support@ottonomy.io"
                  >
                    {" "}
                    {storelanguage === "English"
                      ? "Terms & Conditions"
                      : "Términos y condiciones"}{" "}
                  </Link>{" "}
                </p>
              </div>
            </div>
            <div className="BillDetails_page_BillTotal_wrapper">
              {storetype === "self" && (
                <div className="d_flex_center">
                  <h3 className="!text-black">
                    {" "}
                    {storelanguage === "English"
                      ? "Cart"
                      : "Detalles del pedido"}
                  </h3>
                </div>
              )}
              <div className="DetailsPage-content-wrapper">
                {cart && cart.length > 0 && (
                  <>
                    {cart
                      .filter(
                        (cartObj, index) =>
                          cart.findIndex(
                            (item) =>
                              Object.keys(item)[0] === Object.keys(cartObj)[0]
                          ) === index
                      )
                      .map((cartItem, index) => {
                        return (
                          <Fragment key={"cart__item__no__" + index}>
                            {!Array.isArray(Object.values(cartItem)[0]) ? (
                              <div
                                key={"cartItem" + index}
                                className={`mb-2 cart__item__list__container ${
                                  cart.length === 1 &&
                                  "border-b-[0.25rem] border-b-[var(--theme-primary)] !mb-0 pb-[12px]"
                                }`}
                              >
                                <>
                                  <div className="h-full product-content-wrapper">
                                    {/* <span
                                className="cart__order__delete"
                                onClick={() =>
                                  deleteCartItem(cartItem.product, cartItem)
                                }
                              >
                                <RxCross2 className="cart__order__delete__icon" />
                              </span> */}
                                    {/* <div className="product-content-wrapper-inner-div-one">
                                <img
                                  src={
                                    Array.isArray(Object.values(cartItem)[0])
                                      ? Object.values(cartItem)[0][0].product
                                          .image
                                      : cartItem.product?.image
                                  }
                                  className="product-images-class"
                                  alt=""
                                />
                              </div> */}
                                    <div className="product-content-wrapper-inner-div-two">
                                      <h4 className="!text-[14px] !leading-5 !line-clamp-2">
                                        {" "}
                                        {storelanguage === "English" ? (
                                          <>
                                            {Array.isArray(
                                              Object.values(cartItem)[0]
                                            )
                                              ? `${
                                                  Object.values(cartItem)[0][0]
                                                    .product?.en?.name
                                                } - ${
                                                  Object.values(cartItem)[0][0]
                                                    .productVariant?.name?.en
                                                }`
                                              : cartItem.product?.en?.name}
                                          </>
                                        ) : (
                                          <>
                                            {Array.isArray(
                                              Object.values(cartItem)[0]
                                            )
                                              ? `${
                                                  Object.values(cartItem)[0][0]
                                                    .product?.it?.name
                                                } - ${
                                                  Object.values(cartItem)[0][0]
                                                    .productVariant?.name?.it
                                                }`
                                              : cartItem.product?.it?.name}
                                          </>
                                        )}{" "}
                                      </h4>
                                      {/* <span className="Bill_Details_content_itemcount"> */}
                                      {/* x {cartItem.count} */}
                                      {/* {variantCount(cartItem)} */}
                                      {/* </span> */}
                                      {/* <div className='Bill_Details_content_itemcount'> <h3>  </h3></div>  */}
                                    </div>
                                  </div>
                                  <div className="cart__product__list__container">
                                    <div className="cart__product__list__wrapper">
                                      {cart && (
                                        <>
                                          <button
                                            className="ItemsListPage-ItemsList-Counter-btn !py-[5px]"
                                            onClick={() => {
                                              // console.log(cartItem.product, "CART ITEM PRODUCT")
                                              // console.log(cartItem, "CART ITEM")
                                              let variantDetails =
                                                Object.values(cartItem)[0];

                                              if (
                                                typeof variantDetails ===
                                                "number"
                                              ) {
                                                variantDetails = undefined;
                                              }
                                              // console.log("variant Details", variantDetails)
                                              dispatch(
                                                DecrementCount(
                                                  cartItem.product,
                                                  cart,
                                                  totalPrice,
                                                  variantDetails
                                                )
                                              );
                                            }}
                                          >
                                            {" "}
                                            -{" "}
                                          </button>
                                          <input
                                            type="number"
                                            style={{
                                              WebkitAppearance: "none",
                                              margin: 0,
                                              color: "var(--theme-primary)",
                                              minWidth: "20px",
                                            }}
                                            readOnly
                                            // value={calculateQuantity(cartItem.product, cart)}
                                            value={variantCount(cartItem)}
                                            className="ItemsListPage-ItemsList-Counter-input"
                                          />
                                          <button
                                            className="ItemsListPage-ItemsList-Counter-btn !py-[5px]"
                                            onClick={() => {
                                              let variantDetails =
                                                Object.values(cartItem)[0];

                                              if (
                                                typeof variantDetails ===
                                                "number"
                                              ) {
                                                variantDetails = undefined;
                                              }
                                              // console.log("variant Details", variantDetails)

                                              dispatch(
                                                IncrementCount(
                                                  cartItem.product,
                                                  cart,
                                                  totalPrice,
                                                  variantDetails
                                                )
                                              );
                                            }}
                                          >
                                            {" "}
                                            +{" "}
                                          </button>{" "}
                                        </>
                                      )}
                                    </div>
                                    <span className="cart__order__price text-[14px] !font-normal">
                                      {Array.isArray(Object.values(cartItem)[0])
                                        ? currencyFormatter(
                                            Object.values(
                                              cartItem
                                            )[0][0]?.product?.price.toFixed(2),
                                            storeCurrency
                                          )
                                        : currencyFormatter(
                                            (
                                              cartItem.product?.price *
                                              itemsCount(cartItem)
                                            ).toFixed(2),
                                            storeCurrency
                                          )}
                                    </span>
                                  </div>
                                </>
                              </div>
                            ) : (
                              <div
                                key={"cartItem" + index}
                                className={`px-[10px] ${
                                  index !==
                                  cart.filter(
                                    (cartObj, index) =>
                                      cart.findIndex(
                                        (item) =>
                                          Object.keys(item)[0] ===
                                          Object.keys(cartObj)[0]
                                      ) === index
                                  ).length -
                                    1
                                    ? "border-b-[#ddd] border-b-[2px] border-solid pb-[12px] mb-2"
                                    : "border-b-[var(--theme-primary)] border-b-[0.25rem] border-solid pb-[12px] mb-2"
                                } items-center flex ${
                                  index !== 0 ? "pt-[1rem]" : ""
                                }`}
                              >
                                <div className="h-full product-content-wrapper">
                                  <div className="gap-2 product-content-wrapper-inner-div-two">
                                    <h4 className="!text-[14px] !leading-5 !line-clamp-2">
                                      {storelanguage === "English" ? (
                                        <>
                                          {
                                            Object.values(cartItem)[0][0]
                                              .product?.en?.name
                                          }
                                        </>
                                      ) : (
                                        <>
                                          {
                                            Object.values(cartItem)[0][0]
                                              .product?.it?.name
                                          }
                                        </>
                                      )}
                                    </h4>
                                    <div className="mb-0 variant__increment__modal__variant__details__wrapper">
                                      <div className="variant__increment__modal__variant__wrapper">
                                        {cart && (
                                          <CalculateVariantsQuantity
                                            key={
                                              "calculate__variant__quantity__" +
                                              index
                                            }
                                            cartItems={cart.reduce(
                                              (newCartItem, oldCartItem) => {
                                                if (
                                                  Array.isArray(
                                                    Object.values(
                                                      oldCartItem
                                                    )[0]
                                                  ) &&
                                                  Object.keys(cartItem)[0] ===
                                                    Object.keys(oldCartItem)[0]
                                                ) {
                                                  newCartItem.push(
                                                    Object.values(
                                                      oldCartItem
                                                    )[0][0]
                                                  );
                                                }
                                                return newCartItem;
                                              },
                                              []
                                            )}
                                            // setIncrementVariantsModal={setIncrementVariantsModal}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* {index !==
                              cart.filter(
                                (cartObj, index) =>
                                  cart.findIndex(
                                    (item) =>
                                      Object.keys(item)[0] ===
                                      Object.keys(cartObj)[0]
                                  ) === index
                              ).length -
                                1 && (
                              <hr className="m-0 border-solid border-b border-b-[rgba(0,0,0,1)] opacity-30" />
                            )} */}
                          </Fragment>
                        );
                      })}
                  </>
                )}
                {/* <div className="mb-2 cart__item__list__container">
                  Selected Loc
                </div> */}
                <div className="BillDetails_item_topay_wrapper">
                  <div className="self-center text_align_start">
                    {" "}
                    <p className="!text-[14px] !text-black">
                      {" "}
                      {storelanguage === "English"
                        ? "Selected Location:"
                        : "Punto de recogida seleccionado:"}{" "}
                    </p>
                  </div>
                  <div className="flex flex-col text_align_end">
                    {" "}
                    <span className="!font-normal !text-[14px] !text-black">
                      {storelanguage === "English"
                        ? locationName.group.split("_").join(" ")
                        : locationName.group.split("_").join(" ")}
                    </span>
                    <span className="!font-bold !text-[14px] !text-[var(--theme-primary)] !capitalize">
                      {storelanguage === "English"
                        ? locationName.locationName
                            .split("_")
                            .join(" ")
                            .toLowerCase()
                        : locationName.spanishLocationName
                            .split("_")
                            .join(" ")
                            .toLowerCase()}
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="DetailsPage-content-wrapper !px-[20px]">
                {storetype === "self" && (
                  <>
                    {cart &&
                      cart?.map((cartItem, index) => {
                        return (
                          <div
                            key={"cart__item__no__" + index}
                            className=" BillDetails_item_total_wrapper"
                          >
                            <div className="text_align_start">
                              {" "}
                              <p
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                {" "}
                                {storelanguage === "English" ? (
                                  <>
                                    {Array.isArray(Object.values(cartItem)[0])
                                      ? Object.values(cartItem)[0][0].product
                                          ?.en?.name
                                      : cartItem.product?.en?.name}
                                  </>
                                ) : (
                                  <>
                                    {Array.isArray(Object.values(cartItem)[0])
                                      ? Object.values(cartItem)[0][0].product
                                          ?.it?.name
                                      : cartItem.product?.it?.name}
                                  </>
                                )}
                              </p>
                            </div>
                            <div
                              className="text_align_end"
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              {" "}
                              {variantCount(cartItem)}
                             
                            </div>
                          </div>
                        );
                      })}{" "}
                  </>
                )}
                <div className="BillDetails_item_topay_wrapper">
                  <div className="text_align_start">
                    {" "}
                    <p>
                      {" "}
                      {storelanguage === "English"
                        ? "Selected Delivery Location:"
                        : "Punto de recogida seleccionado:"}{" "}
                    </p>
                  </div>
                  <div className="text_align_end">
                    {" "}
                    <span
                      style={{
                        fontWeight: "800",
                      }}
                    >
                      {" "}
                      {storelanguage === "English"
                        ? locationName.locationName.split("_").join(" ")
                        : locationName.spanishLocationName.split("_").join(" ")}
                    </span>
                  </div>
                </div>
              </div> */}
            </div>
            <div
              className={`Billpage_proceed_tonext_btn_wrapper ${
                formik.values.contactno.replace(
                  process.env.REACT_APP_PHONE_NUMBER_PREFIX,
                  ""
                ) !== "" &&
                formik.values.username !== "" &&
                formik.values.agree
                  ? "!bg-[var(--theme-primary)]"
                  : "!bg-[#707070]"
              }`}
            >
              <h3 className="!text-white text-[22px]">
                {" "}
                {currencyFormatter(
                  (totalPrice + deliveryCharge).toFixed(2),
                  storeCurrency
                )}
              </h3>
              <button
                className={`BillPage_proceed_to_btn !text-[16px] ${
                  formik.values.contactno.replace(
                    "+" + process.env.REACT_APP_PHONE_NUMBER_PREFIX,
                    ""
                  ) !== "" &&
                  formik.values.username !== "" &&
                  formik.values.agree
                    ? "!text-[var(--theme-primary)]"
                    : "!text-[#707070]"
                }`}
                disabled={
                  formik.values.contactno.replace(
                    process.env.REACT_APP_PHONE_NUMBER_PREFIX,
                    ""
                  ) !== "" &&
                  formik.values.username !== "" &&
                  formik.values.agree
                    ? false
                    : true
                }
              >
                {" "}
                {storelanguage === "English" ? "Continue" : "Continuar"}
              </button>
              {/* )} */}
            </div>
            {/* <div className="Billpage_proceed_tonext_btn_wrapper">
              <h3 className="!text-white text-2xl">
                {" "}
                {currencyFormatter(
                  (totalPrice + deliveryCharge).toFixed(2),
                  storeCurrency
                )}
              </h3>
              {formik.errors.agree && formik.touched.agree ? (
                <p className="SelectDelivery_page_error_p">
                  {" "}
                  {storelanguage === "English"
                    ? "Please Agree to Terms and conditions"
                    : "Por favor acepte los términos y condiciones"}{" "}
                </p>
              ) : (
                <>
                  {formik.values.contactno === "" ? (
                    <div className="Details_page_disabled_button">
                      {" "}
                      {storelanguage === "English"
                        ? "Continue"
                        : "Continuar"}{" "}
                    </div>
                  ) : (
                    <button
                      type="submit"
                      className="Details_page__button"
                      style={{ cursor: "pointer" }}
                    >
                      {storelanguage === "English" ? "Continue" : "Continuar"}
                    </button>
                  )}
                </>
              )}
            </div> */}
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Details;
