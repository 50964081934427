import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Header from './Header'
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { DecrementCount, IncrementCount } from '../redux/Actions';
import { relayitemslist } from '../Data/ItemList'
import Product from './Product/Product';
import { useTranslation } from "react-i18next";
import Footer from './Footer';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


function ItemsListTwo() {
    const checkvar=true
    const [totalItems, settotalItems]=useState(0)
    const [totalcost,settotalcost] =useState(0)
    const [count1 , setcount1] =useState(0)
    const [count2 , setcount2] =useState(0)
    const [count3 , setcount3] =useState(0)
    const [count4 , setcount4] =useState(0)
    const data = useLocation()
    const navigate= useNavigate()
    const dispatch =useDispatch()
    var cartData =[]  
    const countdata=useSelector(state=> state.ItemCounter)
    const listdata=useSelector(state=> state.ItemsListReducer)
    

     const handlecartupdate=(items)=>{    
      cartData.push(items)
      // console.log('cartData', cartData)
      listdata?.itemslist?.map((items)=>{
                if(items.count>0){
                   settotalItems(totalItems+1)
                    // console.log('totalItems',totalItems)
                    // settotalcost(totalcost+items.price)
                  }       
              })
     }
    const handlecartupdatetwo=()=>{
        listdata?.itemslist?.map((items)=>{
            if(items.count>0){
               settotalItems(totalItems=> totalItems-1)
                // console.log('totalItems',totalItems)
                for(var i=0;i<items.count ;i++){
                settotalcost(totalcost=> totalcost-items.price)
              }}       
          })
    }

    const handleNextButton=()=>{
      //   listdata?.itemslist?.map((items)=>{
      //       if(items.count>0){
      //        cartData.push(items)                
      //       }   
      //  }) if(count1>0){
        cartData.push([{
            count:count1,
            itemName:'Coke',
            itemImage:'/assets/images/coke.png'    
        }, {
           count:count2,
           itemName:'Diet Coke',
           itemImage:'/assets/images/diet-coke.png'        
        },{
          count:count3,
          itemName:'Water Bottle',
          itemImage:'/assets/images/bottle.png' 
        },{
           count:count4,
           itemName:'Sprite bottle',
           itemImage:'/assets/images/sprite.png' 
        }])
        // console.log('CartData',cartData)
        setTimeout(()=>{
            navigate('/selectDetails', {state:cartData})
            //  window.location.reload()
        },1000)
       }
    const { t } = useTranslation()     
    return (
    <> 
     <div className='MainWhole-wrapper-container'>  
    <Header arrow={false}/>
    <div className='ItemListMain-Wrapper'>
    <h2>Select your items</h2>
         {/* <div className='ItemList_Page_Logos_Wrapper'> 
            <div className="ItemList_Logo_div_one"> 
                <img src="/assets/images/xBridge_logo (1).png"/>
            </div>
            <div className="ItemList_Logo_div_two">       
                <img src="/assets/images/pittsburghlogo.png"/>
           </div>
        </div>
        <div className="Logos_Below_div_wrapper"> 
           <p> {t('Item_list_two_page_Heading_text')}</p>
        </div>  */}
        <div className="ItemList_Page_2_List_wrapper_Main_Whole"> 
                    <Card sx={{ maxWidth: 200, margin:'15px', borderRadius:"20px", boxShadow:'none',position:'relative' }} className="ItemList_page_Single_Item">
                    <CardMedia
                      component="img"
                      image='/assets/images/coke.png'
                      alt="green iguana"
                      style={{borderRadius:"20px" , background:'linear-gradient(to bottom ,rgb(223 16 30), rgb(219 106 109))'}}
                      className="Item_Individual_Image"
                    />
                    <CardContent className="Item_Individual_btn_content">
                      <Typography variant="body2" color="text.secondary">
                      <div className='ItemsListPage-ItemsList-Counter-wrapper_Two'> 
                              <button className='ItemsListPage-ItemsList-Counter-btn_Two' onClick={()=>{
                                 if(count1>0){setcount1(count1=> count1 - 1)}
                                } 
                            }> -</button>
                             <input type="number" readOnly value={count1} className="ItemsListPage-ItemsList-Counter-input"/>
                               <button className='ItemsListPage-ItemsList-Counter-btn_Two' onClick={()=>{
                                 setcount1(count1=> count1+ 1)
                                }}> +</button>  
                      </div>
                      </Typography>
                    </CardContent>
                    <p className="ItemList_Image_content"> Coke</p>
                  </Card>
                  <Card sx={{ maxWidth: 200, margin:'15px', borderRadius:"20px", boxShadow:'none',position:'relative' }} className="ItemList_page_Single_Item">
                    <CardMedia
                      component="img"
                     
                      image='/assets/images/diet-coke.png'
                      alt="green iguana"
                      style={{borderRadius:"20px" , background:'linear-gradient(to bottom ,#848484, #DEDEE2)'}}
                      className="Item_Individual_Image"
                   />
                    <CardContent className="Item_Individual_btn_content">
                      <Typography variant="body2" color="text.secondary">
                      <div className='ItemsListPage-ItemsList-Counter-wrapper_Two'> 
                              <button className='ItemsListPage-ItemsList-Counter-btn_Two' onClick={()=>{
                              if(count2>0){setcount2(count2=> count2 - 1)}
                            }
                            }> -</button>
                             <input type="number" readOnly value={count2} className="ItemsListPage-ItemsList-Counter-input"/>
                               <button className='ItemsListPage-ItemsList-Counter-btn_Two' onClick={()=>{
                              setcount2(count2=> count2 + 1)
                             
                                }}> +</button>  
                      </div>
                      </Typography>
                    </CardContent>
                    <p className="ItemList_Image_content"> Diet Coke</p>
                  </Card>
                  <Card sx={{ maxWidth: 200, margin:'15px', borderRadius:"20px", boxShadow:'none',position:'relative' }} className="ItemList_page_Single_Item">
                    <CardMedia
                      component="img"
                     
                      image='/assets/images/bottle.png'
                      alt="green iguana"
                      style={{borderRadius:"20px" , background:'linear-gradient(to bottom ,#024096, #42ABE1)'}}
                      className="Item_Individual_Image"
                      />
                    <CardContent className="Item_Individual_btn_content">
                      <Typography variant="body2" color="text.secondary">
                      <div className='ItemsListPage-ItemsList-Counter-wrapper_Two'> 
                              <button className='ItemsListPage-ItemsList-Counter-btn_Two' onClick={()=>{
                                 if(count3>0){setcount3(count3=> count3 - 1)}
                            }
                            }> -</button>
                             <input type="number" readOnly value={count3} className="ItemsListPage-ItemsList-Counter-input"/>
                               <button className='ItemsListPage-ItemsList-Counter-btn_Two' onClick={()=>{
                                 setcount3(count3=> count3 + 1)
                                
                                }}> +</button>  
                      </div>
                      </Typography>
                    </CardContent>
                    <p className="ItemList_Image_content"> Bottled Water</p>
                  </Card>
                  <Card sx={{ maxWidth: 200, margin:'15px', borderRadius:"20px", boxShadow:'none',position:'relative' }} className="ItemList_page_Single_Item">
                    <CardMedia
                      component="img"
                     
                      image='/assets/images/sprite.png'
                      alt="green iguana"
                      style={{borderRadius:"20px" , background:'linear-gradient(to bottom ,rgb(16,156,74), #1EC464)'}}
                          className="Item_Individual_Image"
                    />
                    <CardContent className="Item_Individual_btn_content">
                      <Typography variant="body2" color="text.secondary">
                      <div className='ItemsListPage-ItemsList-Counter-wrapper_Two'> 
                              <button className='ItemsListPage-ItemsList-Counter-btn_Two' onClick={()=>{
                              if(count4>0){setcount4(count4=> count4 - 1)}
                            }
                            }> -</button>
                             <input type="number" readOnly value={count4} className="ItemsListPage-ItemsList-Counter-input"/>
                               <button className='ItemsListPage-ItemsList-Counter-btn_Two' onClick={()=>{
                             setcount4(count4=> count4 + 1)
                           
                                }}> +</button>  
                      </div>
                      </Typography>
                    </CardContent>
                    <p className="ItemList_Image_content"> Sprite</p>
                  </Card>

        </div>
   
    </div>
     {count1>0 || count2>0 || count3>0 || count4>0 ? <button className="ItemList_Page_Next_button" onClick={(e)=>handleNextButton(cartData)}> {t('Item_list_two_page_Nextbtn_text')} </button>:null } 
    <Footer/>
   </div> 
   </>
  )
}

export default ItemsListTwo