import { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import Header from "../components/Header";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
function Payment() {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const { cart, totalPrice } = useSelector((state) => state.IC);
  const { storelanguage } = useSelector((state) => state.storelanguagereducer);

  // if (
  //   !Array.isArray(cart) &&
  //   typeof cart === "object" &&
  //   localStorage.getItem("persist:root")
  // ) {
  //   localStorage.setItem(
  //     "persist:root",
  //     '{"IC":"{\\"cart\\":[],\\"totalitems\\":0,\\"totalPrice\\":0}","ItemsListReducer":"[]","otpNumberReducer":"[]","paymentReducer":"{\\"Payment_refrence\\":{}}","storeCurrencyReducer":"{}","storelanguagereducer":"{\\"storelanguage\\":\\"English\\"}","storemapIdreducer":"{}","_persist":"{\\"version\\":-1,\\"rehydrated\\":true}"}'
  //   );
  //   window.location.reload(false);
  // }

  const cartData = useLocation();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ORDERAT}/config`, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
        },
      })
      .then(async (r) => {
        const { publishableKey } = await `${r.data.publishableKey}`;
        setStripePromise(loadStripe(r.data.publishableKey));
      });
  }, []);

  useEffect(() => {
    let totalpricearray = [];
    cartData?.state?.checkoutData?.map((item) => {
      return totalpricearray.push(item.count * item.price);
    });
    const totalprice =
      totalpricearray.reduce((a, b) => a + b, 0) +
      cartData?.state?.cartdata?.state?.deliveryCharge +
      parseFloat(cartData?.state?.cartdata?.state?.totaltax);
    //  console.log('cartData',cartData,totalprice)
    //  console.log('cartDatacartData',cartData)
    // console.log(totalprice.toFixed(2)*100)
    axios
      .post(
        `${process.env.REACT_APP_ORDERAT}/create-payment-intent`,
        {
          totalprice: cartData?.state?.availableDiscount?.discountedAmount ? (parseInt(cartData?.state?.availableDiscount?.totalAfterDiscount).toFixed(2)+
          cartData?.state?.cartdata?.state?.deliveryCharge +
          parseFloat(cartData?.state?.cartdata?.state?.totaltax)) * 100 : (parseInt((totalPrice).toFixed(2)+
          cartData?.state?.cartdata?.state?.deliveryCharge +
          parseFloat(cartData?.state?.cartdata?.state?.totaltax)) * 100),
          orderId: cartData?.state?.orderId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "*",
          },
        }
      )
      .then(async (result) => {
        // console.log('result',result)
        var { clientSecret } = await `${result.data.clientSecret}`;
        setClientSecret(result.data.clientSecret);
        // console.log('result',result)
      });
  }, []);
  useEffect(() => {
    if (
      !cartData?.state ||
      cartData?.state === undefined ||
      cartData?.state === null ||
      cartData?.state === "null"
    ) {
      window.location.replace("/");
    }
  }, [cartData?.state]);
  return (
    <>
      {/* {console.log('cartData',cartData)} */}
      <div className="MainWhole-wrapper-container">
        <Header />
        <div className="Checkout-StripeMain-wrapper">
          <h1>Stripe</h1>
          {storelanguage === "English" ? (
            <h3 className="!text-[18px]">Enter Your Payment Details</h3>
          ) : (
            <h3 className="!text-[18px]">Introduce los datos de tu tarjeta</h3>
          )}
          {clientSecret && stripePromise && (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <CheckoutForm cartData={cartData} clientSecret={clientSecret} />
            </Elements>
          )}
        </div>
      </div>
    </>
  );
}

export default Payment;
