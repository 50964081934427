import React from 'react'

function LandingPageHeader() {
  return (
    <div className='Landing_page_Header_image_wrapper'>
        <img src="https://imagedelivery.net/HOqw_0sYZTUgFQ-soBOgtg/cb2ccedc-892f-4e19-ebb9-7d504013ab00/public"/>
    </div>
  )
}

export default LandingPageHeader