import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import i18next from "i18next";
import { useEffect } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { MdArrowBackIosNew } from "react-icons/md";
import { SlArrowLeft } from "react-icons/sl";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import HomeIcon from "@mui/icons-material/Home";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { storelanguageaction } from "../redux/Actions";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
function Header(props) {
  const { storelanguage } = useSelector((state) => state.storelanguagereducer);
  const [currentlanguage, setcurrentlanguage] = useState(storelanguage);
  const language = localStorage.getItem("language");
  const handlelogoutbtn = () => {
    localStorage.setItem("email", null);
    localStorage.setItem("password", null);
    localStorage.setItem("language", null);
    sessionStorage.removeItem("deliveryLocation");
    navigate(-1);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleBackbutton = () => {
    navigate(-1);
  };
  const [isscroll, setisscroll] = useState(false);
  useEffect(() => {
    window.addEventListener("Scroll", setisscroll(!isscroll));
  }, []);

  const handlehelp = () => {
    navigate("/help");
  };

  const handlehomeicon = () => {
    if (process.env.REACT_APP_CHANGE_HOME) {
      sessionStorage.removeItem("deliveryLocation");
      navigate("/");
    } else {
      sessionStorage.removeItem("deliveryLocation");
      navigate("/storelist");
    }
  };
  return (
    <div
      className={
        props.open || props.opentwo
          ? "MainHeader-wrapper_copy headerscroll"
          : "MainHeader-wrapper"
      }
    >
      {/* {console.log('isscroll',isscroll)} */}
      <div className="MainHeader-logo-div-wrapper">
        <div className={`MainHeader-logo-div pl-[15px]`}>
          {props.arrow ? (
            <Link to={"/"} onClick={handlehomeicon}>
              <img
                // src={`/assets/images/sodexo__logo.png`}
                src={
                  process.env.REACT_APP_ORDERAT +
                  "/site/" +
                  process.env.REACT_APP_SITE_NAME +
                  "/getAppLogo"
                }
                alt=""
                style={{
                  height: "35px",
                  width: "auto",
                  marginBottom: process.env.REACT_APP_LOGO_MARGIN,
                }}
              />
            </Link>
          ) : (
            <>
              <>
                {!props.congratulations && (
                  <span
                    className="flex items-center w-7 h-9"
                    onClick={handlelogoutbtn}
                  >
                    {" "}
                    <SlArrowLeft className="cursor-pointer text-[var(--theme-primary)] h-full w-full" />
                    {/* <img
                      src={`/assets/images/back_icon.png`}
                      alt=""
                      style={{
                        height: "25px",
                        cursor: "pointer",
                      }}
                      // onClick={handlelogoutbtn}
                    /> */}
                    {/* <ArrowBackIosNewIcon
                onClick={handleBackbutton}
                style={{
                  cursor: "pointer",
                  fontSize: "39.4px",
                  color: "#ff3127",
                }}
              />{" "} */}
                  </span>
                )}
              </>

              <Link to={"/"} onClick={handlehomeicon}>
                <img
                  // src={`/assets/images/sodexo__logo.png`}
                  src={
                    process.env.REACT_APP_ORDERAT +
                    "/site/" +
                    process.env.REACT_APP_SITE_NAME +
                    "/getAppLogo"
                  }
                  alt=""
                  style={{
                    height: "35px",
                    width: "auto",
                    marginBottom: process.env.REACT_APP_LOGO_MARGIN,
                  }}
                />
              </Link>
            </>
          )}
        </div>
        <div className="gap-2 d_flex_center">
          {process.env.REACT_APP_STORE_LANGUAGE &&
            JSON.parse(process.env.REACT_APP_STORE_LANGUAGE).length > 1 && (
              <Dropdown
                onSelect={(eventKey) => {
                  setcurrentlanguage(eventKey);
                  dispatch(storelanguageaction(eventKey));
                }}
                id="Store_dropdown_select"
              >
                <Dropdown.Toggle id="" className="!text-[14px]">
                  {currentlanguage === "English" ? (
                    <img
                      className="thumbnail-image"
                      src="/assets/images/2560px-Flag_of_the_United_States.svg.png"
                      alt="user pic"
                    />
                  ) : (
                    <img
                      className="thumbnail-image"
                      src="/assets/images/spainFlag.png"
                      alt="user pic"
                    />
                  )}{" "}
                  {currentlanguage}{" "}
                </Dropdown.Toggle>
                <Dropdown.Menu className="custom-dropdown-menu">
                  {JSON.parse(process.env.REACT_APP_STORE_LANGUAGE)?.map(
                    (language, index) => (
                      <Dropdown.Item
                        key={language.name}
                        eventKey={language.name}
                      >
                        <img
                          className="thumbnail-image"
                          src={language.src}
                          alt="language__image"
                        />{" "}
                        {language.name}
                      </Dropdown.Item>
                    )
                  )}
                </Dropdown.Menu>
              </Dropdown>
            )}
          {props?.help ? (
            <div className="MainHeader_second_div">
              <HomeIcon
                onClick={handlehomeicon}
                sx={{
                  color: "var(--theme-primary)",
                  fontSize: "35px",
                  cursor: "pointer",
                }}
              />
            </div>
          ) : (
            <>
              {props?.profile ? (
                <div className="Congratulation_header_2">
                  <PersonOutlineOutlinedIcon />
                  <div>
                    <h5>
                      {
                        props?.orderData?.state?.state?.cartdata?.state
                          ?.username
                      }{" "}
                    </h5>
                    <p>
                      {" "}
                      +
                      {
                        props?.orderData?.state?.state?.cartdata?.state
                          ?.contactNo
                      }
                    </p>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className="text-[1.1rem] MainHeader_second_div flex items-center gap-1"
                    style={{ cursor: "pointer" }}
                    onClick={handlehelp}
                  >
                    <span>
                      {" "}
                      <HelpOutlineOutlinedIcon
                        sx={{
                          color: "var(--theme-primary)",
                          height: "25px!important",
                          width: "25px!important",
                        }}
                      />{" "}
                    </span>{" "}
                    {storelanguage === "English" ? "Help" : "Ayuda"}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
