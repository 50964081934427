import { Box, Modal, Rating } from "@mui/material";
import React, { useEffect, useState } from "react";
import VariantToggleButtonGroup from "./VariantToggleButtonGroup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  DecrementCount,
  IncrementCount,
  mergeVariants,
} from "../redux/Actions";
import { RxCross2 } from "react-icons/rx";
import RecommendedProduct from "./RecommendedProduct";
import { currencyFormatter } from "../utils";

const ProductDetails = ({
  product,
  setOpen,
  lastdata,
  setlastdata,
  storedata,
  countarray,
  totalcount,
}) => {
  const [selectedVariantDetails, setSelectedVariantDetails] = useState();
  const [selectedTempVariantDetails, setSelectedTempVariantDetails] =
    useState();
  const [selectedProductDetails, setSelectedProductDetails] = useState();
  const [openVariants, setOpenVariants] = useState(false);
  const [incrementVariantsModal, setIncrementVariantsModal] = useState(false);
  const [changestate, setchanegState] = useState(false);
  const [variantCount, setVariantCount] = useState(1);
  const [variantTempDetails, setVariantTempDetails] = useState([]);
  const [totalTempPrice, setTotalTempPrice] = useState(0);
  const [openRecommendedProducts, setOpenRecommendedProducts] = useState(false);
  const [multipleVariants, setMultipleVariants] = useState([]);

  const { storeCurrency } = useSelector((state) => state.storeCurrencyReducer);
  const { storelanguage } = useSelector((state) => state.storelanguagereducer);
  const { cart, totalitems, totalPrice } = useSelector((state) => state.IC);

  // if (
  //   !Array.isArray(cart) &&
  //   typeof cart === "object" &&
  //   localStorage.getItem("persist:root")
  // ) {
  //   localStorage.setItem(
  //     "persist:root",
  //     '{"IC":"{\\"cart\\":[],\\"totalitems\\":0,\\"totalPrice\\":0}","ItemsListReducer":"[]","otpNumberReducer":"[]","paymentReducer":"{\\"Payment_refrence\\":{}}","storeCurrencyReducer":"{}","storelanguagereducer":"{\\"storelanguage\\":\\"English\\"}","storemapIdreducer":"{}","_persist":"{\\"version\\":-1,\\"rehydrated\\":true}"}'
  //   );
  //   window.location.reload(false);
  // }

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpen = () => setOpen(true);

  const handleClose = (reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  const handleVariantsClose = (reason) => {
    if (reason && reason === "backdropClick") return;
    setOpenVariants(false);
    setSelectedTempVariantDetails();
    setVariantTempDetails([]);
    setTotalTempPrice(0);
    setVariantCount(1);
    setMultipleVariants([]);
  };

  const handleRecommendedProductsClose = (reason) => {
    if (reason && reason === "backdropClick") return;
    setOpenRecommendedProducts(false);
  };

  const handleIncrementVariantsClose = (reason) => {
    if (reason && reason === "backdropClick") return;
    setIncrementVariantsModal(false);
  };

  const incrementTempVariantCount = (
    item,
    cart = [],
    totalprice,
    selectedVariantDetails
  ) => {
    if (selectedVariantDetails && Array.isArray(selectedVariantDetails)) {
      let tempArray = [];
      selectedVariantDetails.map((variantDetail) => {
        tempArray.push({
          [variantDetail.variantProduct.variantId]: 1,
          product: variantDetail.product,
          productVariant: variantDetail.variantProduct,
        });
        return null;
      });

      cart.push({
        [selectedVariantDetails[0].product._id]: tempArray,
      });
      totalprice += selectedVariantDetails[0].product.price;
    } else if (
      selectedVariantDetails &&
      !Array.isArray(selectedVariantDetails)
    ) {
      let tempArray = [];
      tempArray.push({
        [selectedVariantDetails.variantProduct.variantId]: 1,
        product: selectedVariantDetails.product,
        productVariant: selectedVariantDetails.variantProduct,
      });

      cart.push({ [selectedVariantDetails.product._id]: tempArray });
      totalprice += selectedVariantDetails.product.price;
    } else if (!selectedVariantDetails) {
      let cartItemIndex = cart.findIndex(
        (cartItem) => Object.keys(cartItem)[0] === item._id
      );
      if (cartItemIndex > -1) {
        cart[cartItemIndex][item._id] += 1;
        // cart[item._id] += 1;
      } else {
        cart.push({ [item._id]: 1, product: item });
      }
      totalprice += item.price;
    }
    setVariantTempDetails(cart);
    setTotalTempPrice(totalprice);
  };

  const decrementTempVariantCount = (
    item,
    cart = [],
    totalprice,
    selectedVariantDetails
  ) => {
    if (selectedVariantDetails && Array.isArray(selectedVariantDetails)) {
      let tempIndicesArray = [];

      cart.map((cartItem, index) => {
        if (
          Object.keys(cartItem)[0] === selectedVariantDetails[0].product._id
        ) {
          tempIndicesArray.push(index);
        }
        return null;
      });

      cart.splice(tempIndicesArray[tempIndicesArray.length - 1], 1);
      totalprice -= selectedVariantDetails[0].product.price;
    } else if (
      selectedVariantDetails &&
      !Array.isArray(selectedVariantDetails)
    ) {
      let tempIndicesArray = [];

      cart.map((cartItem, index) => {
        if (Object.keys(cartItem)[0] === selectedVariantDetails.product._id) {
          tempIndicesArray.push(index);
        }
        return null;
      });

      cart.splice(tempIndicesArray[tempIndicesArray.length - 1], 1);
      totalprice -= selectedVariantDetails.product.price;
    } else if (!selectedVariantDetails) {
      let cartItemIndex = cart.findIndex(
        (cartItem) => Object.keys(cartItem)[0] === item._id
      );
      if (cartItemIndex > -1) {
        cart[cartItemIndex][item._id] -= 1;
        if (cart[cartItemIndex][item._id] === 0) {
          cart.splice(cartItemIndex, 1);
        }

        totalprice -= item.price;
        // cart[item._id] += 1;
      }
    }

    setVariantTempDetails(cart);
    setTotalTempPrice(totalprice);
  };

  const addToCartTempVariant = (
    item,
    tempCart = [],
    totalprice,
    selectedVariantDetails,
    variantCount,
    multipleVariants
  ) => {
    for (let i = 0; i < variantCount; i++) {
      if (selectedVariantDetails && Array.isArray(selectedVariantDetails)) {
        let tempArray = [];
        selectedVariantDetails.map((variantDetail) => {
          tempArray.push({
            [variantDetail.variantProduct.variantId]: 1,
            product: variantDetail.product,
            productVariant: variantDetail.variantProduct,
          });
          return null;
        });

        tempCart.push({
          [selectedVariantDetails[0].product._id]: tempArray,
        });
        totalprice += selectedVariantDetails[0].product.price;
      } else if (
        selectedVariantDetails &&
        !Array.isArray(selectedVariantDetails)
      ) {
        let tempArray = [];
        if (multipleVariants.length > 0) {
          let multipleVariantProduct = [];
          let multipleVariantId = "";

          multipleVariants.map((variantDetail, index) => {
            if (index === 0) {
              multipleVariantId += variantDetail.variantProduct.variantId;
            } else {
              multipleVariantId += `${
                "_" + variantDetail.variantProduct.variantId
              }`;
            }
            multipleVariantProduct.push(variantDetail.variantProduct);
            return null;
          });

          tempArray.push({
            [multipleVariantId]: 1,
            product: multipleVariants[0].product,
            productVariant: multipleVariants[0].variantProduct,
            multipleVariants: multipleVariantProduct,
          });
        } else {
          tempArray.push({
            [selectedVariantDetails.variantProduct.variantId]: 1,
            product: selectedVariantDetails.product,
            productVariant: selectedVariantDetails.variantProduct,
          });
        }

        tempCart.push({ [selectedVariantDetails.product._id]: tempArray });
        // totalprice += selectedVariantDetails.product.price;
      } else if (!selectedVariantDetails) {
        let cartItemIndex = cart.findIndex(
          (cartItem) => Object.keys(cartItem)[0] === item._id
        );
        if (cartItemIndex > -1) {
          tempCart[cartItemIndex][item._id] += 1;
          // cart[item._id] += 1;
        } else {
          tempCart.push({ [item._id]: 1, product: item });
        }
        totalprice += item.price;
      }

      dispatch(
        mergeVariants(cart, tempCart, totalprice, totalitems, totalPrice)
      );
    }
  };

  const addToCartTempProduct = (
    selectedProductDetails,
    tempCart = [],
    totalTempPrice
  ) => {
    let cartItemIndex = cart.findIndex(
      (cartItem) => Object.keys(cartItem)[0] === selectedProductDetails._id
    );
    if (cartItemIndex > -1) {
      tempCart[cartItemIndex][selectedProductDetails._id] += 1;
      // cart[item._id] += 1;
    } else {
      tempCart.push({
        [selectedProductDetails._id]: 1,
        product: selectedProductDetails,
      });
    }
    // totalTempPrice += selectedProductDetails.price;

    dispatch(
      mergeVariants(cart, tempCart, totalTempPrice, totalitems, totalPrice)
    );
    // setVariantTempDetails(cart);
    // setTotalTempPrice(totalTempPrice);
  };

  // const incrementRecommendedTempVariantCount = (item, cart = [], totalprice, selectedVariantDetails) => {

  // }

  // const decrementRecommendedVariantCount = (item, cart = [], totalprice, selectedVariantDetails) => {
  //     if (selectedVariantDetails && Array.isArray(selectedVariantDetails)) {
  //       let tempIndicesArray = [];

  //       cart.map((cartItem, index) => {
  //         if (
  //           Object.keys(cartItem)[0] === selectedVariantDetails[0].product._id
  //         ) {
  //           tempIndicesArray.push(index);
  //         }
  //         return null;
  //       });

  //       cart.splice(tempIndicesArray[tempIndicesArray.length - 1], 1);
  //       totalprice -= selectedVariantDetails[0].product.price;
  //     } else if (
  //       selectedVariantDetails &&
  //       !Array.isArray(selectedVariantDetails)
  //     ) {
  //       let tempIndicesArray = [];

  //       cart.map((cartItem, index) => {
  //         if (Object.keys(cartItem)[0] === selectedVariantDetails.product._id) {
  //           tempIndicesArray.push(index);
  //         }
  //         return null;
  //       });

  //       cart.splice(tempIndicesArray[tempIndicesArray.length - 1], 1);
  //       totalprice -= selectedVariantDetails.product.price;
  //     } else if (!selectedVariantDetails) {
  //       let cartItemIndex = cart.findIndex(
  //         (cartItem) => Object.keys(cartItem)[0] === item._id
  //       );
  //       if (cartItemIndex > -1) {
  //         cart[cartItemIndex][item._id] -= 1;
  //         if (cart[cartItemIndex][item._id] === 0) {
  //           cart.splice(cartItemIndex, 1);
  //         }

  //         totalprice -= item.price;
  //         // cart[item._id] += 1;
  //       }
  //     }

  //     setVariantTempDetails(cart)
  //     setTotalTempPrice(totalprice);
  // }

  const calculateQuantity = (product, cart, selectedVariantDetails) => {
    // cart[product._id]
    // console.log(product, 'product')
    if (
      product?.variant?.length > 0 &&
      selectedVariantDetails &&
      Array.isArray(selectedVariantDetails)
    ) {
      let quantityCount = 0;

      cart.map((cartItem, index) => {
        if (
          Object.keys(cartItem)[0] === selectedVariantDetails[0].product._id
        ) {
          quantityCount += 1;
        }
        return null;
      });

      return quantityCount;
    } else if (
      product?.variant?.length > 0 &&
      selectedVariantDetails &&
      !Array.isArray(selectedVariantDetails)
    ) {
      let quantityCount = 0;

      cart.map((cartItem, index) => {
        if (Object.keys(cartItem)[0] === selectedVariantDetails.product._id) {
          quantityCount += 1;
        }
        return null;
      });

      return quantityCount;
    } else if (
      product?.variant?.length > 0 &&
      cart.length > 0 &&
      cart.find((cartItem) => Object.keys(cartItem)[0] === product._id)
    ) {
      let quantityCount = cart.filter(
        (cartItem) => Object.keys(cartItem)[0] === product._id
      ).length;
      return quantityCount;
    } else if (!selectedVariantDetails) {
      // console.log("NO VARIANTS");
      let quantityCount = 0;
      let cartItemIndex = cart.findIndex(
        (cartItem) => Object.keys(cartItem)[0] === product._id
      );
      if (cartItemIndex > -1) {
        quantityCount = cart[cartItemIndex][product._id];
      }
      return quantityCount;
    }
  };

  const handleNextButton = () => {
    //  navigate('/selectDetails', {state:lastdata})
    sessionStorage.setItem("nextpage", true);
    localStorage.setItem("lastdata", JSON.stringify(lastdata));
    navigate("/billdetails", {
      state: {
        stateone: lastdata,
        statetwo: storedata.state.item,
        statethree: storedata?.state?.productlist,
      },
    });
  };

  const checkAddButton = (item, selectedVariant) => {
    if (selectedVariant && item.variant.length > 0) {
      return (
        cart[selectedVariant.variantId] === 0 ||
        !cart.hasOwnProperty(selectedVariant.variantId)
      );
    } else if (item.variant.length === 0) {
      return cart[item._id] === 0 || !cart.hasOwnProperty(item._id);
    }
  };

  const variantModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // height:"calc(100% - 50px)",
    width: "calc(100% - 50px)",
    height: "calc(100% - 50px)",
    // height: `${
    //   process.env.REACT_APP_SITE_NAME !== "Sodexo"
    //     ? "calc(100% - 50px)"
    //     : "auto"
    // }`,
    bgcolor: "#ffffff",
    // boxShadow: 24,
    p: 4,
    borderRadius: "10px",
    paddingTop: "0px",
    paddingBottom: "0px",
    border: "none",
    paddingRight: "0px",
    paddingLeft: "0px",
    zIndex: "999999999999999999999999",
    outline: "none",
    overflow: "auto",
  };

  const variantIncrementModalStyle = {
    position: "absolute",
    bottom: "0",
    left: "50%",
    transform: "translate(-50%, -20%)",
    width: "calc(100% - 50px)",
    // height: `calc(100% - 50px)`,
    // height: "30%",
    bgcolor: "#ffffff",
    // boxShadow: 24,
    p: 4,
    borderRadius: "10px",
    paddingTop: "0px",
    paddingBottom: "0px",
    border: "none",
    paddingRight: "0px",
    paddingLeft: "0px",
    zIndex: "999999999999999999999999",
    outline: "none",
  };

  const decrementVariantCount = () => {
    if (variantCount > 1) {
      setVariantCount(variantCount - 1);
      if (selectedTempVariantDetails) {
        setTotalTempPrice(
          totalTempPrice - selectedTempVariantDetails.product.price
        );
      } else {
        setTotalTempPrice(totalTempPrice - selectedProductDetails.price);
      }
    }
  };

  const incrementVariantCount = () => {
    setVariantCount(variantCount + 1);
    if (selectedTempVariantDetails) {
      setTotalTempPrice(
        totalTempPrice + selectedTempVariantDetails.product.price
      );
    } else {
      setTotalTempPrice(totalTempPrice + selectedProductDetails.price);
    }
  };

  const CalculateVariantsQuantity = ({
    cartItems,
    setIncrementVariantsModal,
  }) => {
    // console.log(cartItems, "cartItems");
    let filteredCartItems = cartItems.reduce((uniqueVariant, variant) => {
      if (!uniqueVariant[Object.keys(variant)[0]]) {
        let uniqueVariantObject = Object.keys(variant).includes(
          "multipleVariants"
        )
          ? {
              variantCount: 1,
              product: Object.values(variant)[1],
              productVariant: Object.values(variant)[2],
              multipleVariants: Object.values(variant)[3],
            }
          : {
              variantCount: 1,
              product: Object.values(variant)[1],
              productVariant: Object.values(variant)[2],
            };
        uniqueVariant[Object.keys(variant)[0]] = uniqueVariantObject;
      } else if (uniqueVariant[Object.keys(variant)[0]]) {
        uniqueVariant[Object.keys(variant)[0]].variantCount += 1;
      }

      return uniqueVariant;
    }, {});

    useEffect(() => {
      if (Object.values(filteredCartItems).length === 0) {
        setIncrementVariantsModal(false);
      }
    }, [filteredCartItems, setIncrementVariantsModal]);

    return (
      <div
        className={`variant__increment__variant__wrapper ${
          Object.values(filteredCartItems).length > 3
            ? "h-44 overflow-auto"
            : "h-auto"
        }`}
      >
        {Object.values(filteredCartItems).length > 0 &&
          Object.values(filteredCartItems).map((cartItem, index) => (
            <div
              className="variant__increment__modal__variant__div"
              key={"variant__increment__modal__variant__div__" + index}
            >
              <div className="variant__modal__recommended__product__div__wrapper">
                <div className="variants__modal__recommended__product___details">
                  <span className="capitalize variants__increment__modal__variant___name">
                    {/* {recommendedProduct?.product?.en?.name} */}
                    {cartItem?.multipleVariants?.length > 0 ? (
                      <>
                        {storelanguage === "English"
                          ? `${cartItem.multipleVariants
                              .map((variant) => variant.name.en)
                              .join(", ")
                              .toLowerCase()}`
                          : `${cartItem.multipleVariants
                              .map((variant) => variant.name.it)
                              .join(", ")
                              .toLowerCase()}`}
                      </>
                    ) : (
                      <>
                        {storelanguage === "English"
                          ? `${cartItem.productVariant?.name?.en?.toLowerCase()}`
                          : `${cartItem.productVariant?.name?.it?.toLowerCase()}`}
                      </>
                    )}
                    {/* {storelanguage === "English" ? (
                      <>
                      {cartItem.productVariant.name.en}</>
                    ) : (
                      <>{cartItem.productVariant.name.it}</>
                    )} */}
                  </span>
                  <span className="variants__modal__product___price">
                    {/* {recommendedProduct?.product?.price} */}
                    {currencyFormatter(
                      cartItem.product.price.toFixed(2),
                      storeCurrency
                    )}
                  </span>
                </div>
              </div>
              <div
                className="variant__increment__modal__variant__add__btn__wrapper"
                // className="Productlist_product_counter_wrapper"
              >
                <button
                  className="ItemsListPage-ItemsList-Counter-btn"
                  onClick={() => {
                    dispatch(
                      DecrementCount(
                        cartItem.product,
                        cart,
                        totalPrice,
                        cartItem.product?.variant?.length !== 0
                          ? {
                              product: cartItem.product,
                              variantProduct: cartItem.productVariant,
                              multipleVariants:
                                cartItem?.multipleVariants?.length > 0
                                  ? cartItem?.multipleVariants
                                  : undefined,
                            }
                          : undefined
                      )
                    );
                  }}
                >
                  {" "}
                  -{" "}
                </button>
                <input
                  type="number"
                  style={{
                    WebkitAppearance: "none",
                    margin: 0,
                    color: "var(--theme-primary)",
                    minWidth: "10px",
                  }}
                  readOnly
                  value={cartItem.variantCount}
                  className="ItemsListPage-ItemsList-Counter-input"
                />
                <button
                  className="ItemsListPage-ItemsList-Counter-btn"
                  onClick={() => {
                    // let countnumber = countarray.reduce((a, b) => a + b, 0);
                    if (totalcount >= 8) {
                      // totalitems
                      handleOpen();
                    } else {
                      handleClose();
                      dispatch(
                        IncrementCount(
                          cartItem.product,
                          cart,
                          totalPrice,
                          cartItem.product?.variant?.length !== 0
                            ? {
                                product: cartItem.product,
                                variantProduct: cartItem.productVariant,
                                multipleVariants:
                                  cartItem?.multipleVariants?.length > 0
                                    ? cartItem?.multipleVariants
                                    : undefined,
                              }
                            : undefined
                        )
                      );
                    }
                  }}
                >
                  {" "}
                  +{" "}
                </button>{" "}
              </div>
            </div>
          ))}
      </div>
    );
  };

  const addToCartTempRecommendedProducts = ({
    selectedProductDetails,
    recommendedTempProductsDetails,
    totalRecommendedTempPrice,
  }) => {};

  // useEffect(() => {
  //     if (cart[product._id] > 0) {
  //         product.count = cart[product._id];
  //         lastdata.push(product)
  //       } else if (cart[product._id] <= 0) {
  //         let tempdata = lastdata.filter(
  //             (item) => product._id !== item._id
  //           )
  //         // lastdata = tempdata;
  //         setlastdata(tempdata)
  //         product.addbtn = false;
  //       }
  // },[cart, lastdata, product, setlastdata])

  return (
    <>
      <div
        className="Productlist_page_individual_product"
        id={product?.en?.category}
      >
        <div className="Productlist_page_individual_product_content_wrapper">
          {storelanguage === "English" ? (
            <h2 className="!text-[14px]"> {product?.en?.name}</h2>
          ) : (
            <h2 className="!text-[14px]"> {product?.it?.name}</h2>
          )}

          {storelanguage === "English" ? (
            <div className="my-2 !text-[12px] text-black">
              {" "}
              {product?.en?.description}
            </div>
          ) : (
            <div className="my-2 !text-[12px] text-black">
              {" "}
              {product?.it?.description}
            </div>
          )}

          <p className="Productlist_page_price_p !text-[14px]">
            {" "}
            {currencyFormatter(product?.price.toFixed(2), storeCurrency)}
          </p>

          {storelanguage === "English" ? (
            <span className="label__product !text-[12px]">No packaging cost</span>
          ) : (
            <span className="label__product !text-[12px]">Sin costo de envase</span>
          )}

          {product?.ingredients && product?.ingredients.length > 0 && (
            <div className="product__ingredients">
              {product?.ingredients.map((ingredient, index) => (
                <img
                  key={"ingredients__no__" + index}
                  className="w-5 h-5"
                  src={`${ingredient.image}`}
                  alt="ingredients_icon"
                  title={ingredient.name}
                />
              ))}
            </div>
          )}

          {/* <div className="Productlist_page_rating_div">
            <Rating name="read-only" value={product?.rating} readOnly />{" "}
            <span> {product?.rating}</span>
          </div> */}

          {/* {product?.variant?.length > 0 && (
            <div className="variant__wrapper">
              {product.variant.map((variant, index) => (
                <VariantToggleButtonGroup
                  key={"variant__no__" + index}
                  variant={variant}
                  selectedVariantDetails={selectedVariantDetails}
                  setSelectedVariantDetails={setSelectedVariantDetails}
                  product={product}
                />
              ))}
            </div>
          )} */}
        </div>

        <div className="Productlist_page_individual_product_image_wrapper">
          {product.image ? (
            <img src={product?.image} alt="" />
          ) : (
            <div className="No_image_div"> No Image</div>
          )}
          <div className="Productlist_product_counter_wrapper">
            {cart &&
            (cart.findIndex(
              (cartItem) => Object.keys(cartItem)[0] === product._id
            ) === -1 ||
              cart[
                cart.findIndex(
                  (cartItem) => Object.keys(cartItem)[0] === product._id
                )
              ][product._id] === 0) ? (
              <button
                onClick={() => {
                  if (
                    product?.variant?.length > 0 ||
                    product?.recommendedProducts?.length > 0
                  ) {
                    setOpenVariants(true);
                    // setSelectedTempVariantDetails({
                    //   variantProduct: product?.variant,
                    //   variant: product?.variant,
                    //   product: product,
                    // })

                    // incrementTempVariantCount(
                    //   product,
                    //   variantTempDetails,
                    //   totalTempPrice,
                    //   product?.variant?.length !== 0
                    //     ? {
                    //         variantProduct: product?.variant,
                    //         variant: product?.variant,
                    //         product: product,
                    //       }
                    //     : undefined
                    // );

                    setTotalTempPrice(product.price);

                    if (product?.variant?.length > 0) {
                      setSelectedTempVariantDetails({
                        variantProduct: product?.variant,
                        variant: product?.variant,
                        product: product,
                      });
                    }
                    // else {
                    //   setSelectedTempVariantDetails()
                    // }

                    // setVariantTempDetails([...variantTempDetails, product.variant[0].product[0]])
                    // setTotalTempPrice(product?.price)

                    setSelectedProductDetails(product);

                    if (
                      selectedVariantDetails &&
                      !Array.isArray(selectedVariantDetails) &&
                      product?.variant?.length > 0 &&
                      cart.length > 0 &&
                      cart.find(
                        (cartItem) => Object.keys(cartItem)[0] === product._id
                      )
                    ) {
                      setSelectedProductDetails(product);
                      setIncrementVariantsModal(true);
                    }
                  } else {
                    if (totalcount >= 4) {
                      handleOpen();
                    } else {
                      handleClose();
                      product.addbtn = true;
                      setchanegState(!changestate);
                      dispatch(
                        IncrementCount(
                          product,
                          cart,
                          totalPrice,
                          product?.variant?.length !== 0
                            ? selectedVariantDetails
                            : undefined
                        )
                      );
                    }
                  }
                }}
              className="!text-[14px]"
              >
                {" "}
                {storelanguage === "English" ? "ADD" : "Agregar"}
              </button>
            ) : (
              <>
                <button
                  className="ItemsListPage-ItemsList-Counter-btn"
                  onClick={() => {
                    if (
                      // selectedVariantDetails &&
                      // !Array.isArray(selectedVariantDetails) &&
                      product?.variant?.length > 0 &&
                      cart.length > 0 &&
                      cart.find(
                        (cartItem) => Object.keys(cartItem)[0] === product._id
                      )
                    ) {
                      setSelectedProductDetails(product);
                      setIncrementVariantsModal(true);
                    } else {
                      dispatch(
                        DecrementCount(
                          product,
                          cart,
                          totalPrice,
                          product?.variant?.length !== 0
                            ? selectedVariantDetails
                            : undefined
                        )
                      );
                    }
                  }}
                >
                  {" "}
                  -{" "}
                </button>
                <input
                  type="number"
                  style={{
                    WebkitAppearance: "none",
                    margin: 0,
                    color: "var(--theme-primary)",
                    minWidth: "10px",
                  }}
                  readOnly
                  value={calculateQuantity(
                    product,
                    cart,
                    product?.variant?.length !== 0
                      ? selectedVariantDetails
                      : undefined
                  )}
                  className="ItemsListPage-ItemsList-Counter-input"
                />
                <button
                  className="ItemsListPage-ItemsList-Counter-btn"
                  onClick={() => {
                    let countnumber = countarray.reduce((a, b) => a + b, 0);
                    if (totalcount >= 8) {
                      // totalitems
                      handleOpen();
                    } else {
                      handleClose();
                      if (
                        // selectedVariantDetails &&
                        // !Array.isArray(selectedVariantDetails) &&
                        product?.variant?.length > 0 &&
                        cart.length > 0 &&
                        cart.find(
                          (cartItem) => Object.keys(cartItem)[0] === product._id
                        )
                      ) {
                        setSelectedProductDetails(product);
                        setIncrementVariantsModal(true);
                      } else {
                        dispatch(
                          IncrementCount(
                            product,
                            cart,
                            totalPrice,
                            product?.variant?.length !== 0
                              ? selectedVariantDetails
                              : undefined
                          )
                        );
                      }
                    }
                  }}
                >
                  {" "}
                  +{" "}
                </button>{" "}
              </>
            )}
          </div>
        </div>
      </div>
      <Modal open={openVariants}>
        <Box
          sx={{ ...variantModalStyle }}
          className="variants__modal__container"
        >
          <div className="variants__modal__wrapper">
            <span
              onClick={handleVariantsClose}
              className="variant__modal__close__btn"
            >
              <RxCross2 className="variant__modal__close__btn__icon" />
            </span>
            <div className="variants__modal__product">
              <div className="variant__modal__product__div">
                <div className="flex gap-[20px] max-xsm:flex-col ">
                  <div className="shadow-[0px_0px_6px_#0000001a] rounded-[10px] min-w-[100px] min-h-[100px] w-[100px] h-[100px]">
                    <img
                      className="variants__modal__product__image p-[10px]"
                      src={selectedProductDetails?.image}
                      alt="product__image"
                    />
                  </div>
                  <div className="variants__modal__product___details">
                    <span className="variants__modal__product___name">
                      {storelanguage === "English" ? (
                        <>
                          {selectedProductDetails &&
                            selectedProductDetails.en.name}
                        </>
                      ) : (
                        <>
                          {selectedProductDetails &&
                            selectedProductDetails.it.name}
                        </>
                      )}
                      {/* Mini Croissants Combo - 4 units */}
                    </span>
                    <span className="variants__modal__product___price">
                      {currencyFormatter(
                        selectedProductDetails?.price.toFixed(2),
                        storeCurrency
                      )}
                    </span>
                    <div className="variants__modal__product___ingredients">
                      {product?.ingredients.map((ingredient, index) => (
                        <img
                          key={"variant__ingredients__no__" + index}
                          className="variants__modal__product__ingredients__image"
                          src={`${ingredient.image}`}
                          alt="ingredients_icon"
                        />
                      ))}
                    </div>
                  </div>
                </div>
                {selectedProductDetails?.variant.length === 0 && (
                  <div
                    className="flex items-center justify-center px-4 py-2 bg-[var(--theme-primary)] text-white font-bold rounded-md cursor-pointer text-sm"
                    onClick={() => {
                      addToCartTempProduct(
                        selectedProductDetails,
                        [],
                        totalTempPrice
                      );
                      setOpenVariants(false);
                      setVariantCount(1);
                      setTotalTempPrice(0);
                      setVariantTempDetails([]);
                    }}
                  >
                    {storelanguage === "English"
                      ? "Add to Cart"
                      : "añadir a la cesta"}
                  </div>
                )}
              </div>
              {(selectedProductDetails?.variant.length > 0 || selectedProductDetails?.recommendedProducts.length > 0) && (
                <hr className="variant__modal__divider !border-b-[2px] !mx-0" />
              )}
              <div className="gap-4 variant__modal__variants__div">
                {/* <div className="variant__modal__variants__options"> */}
                {product?.variant?.map((variant, index) => (
                  <div
                    className={`flex flex-col ${
                      index === product?.variant?.length - 1 && "flex-grow"
                    }`}
                    key={"variant__no__" + index}
                  >
                    {selectedProductDetails?.variant.length > 0 && (
                      <div className="variant__modal__variants__details">
                        {storelanguage === "English" ? (
                          <>
                            <span className="variant__modal__variant__name">
                              {variant?.variantType?.en}
                              {/* Variants */}
                            </span>
                            <span className="variant__modal__variant__select__label">
                              Select Any One
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="variant__modal__variant__name">
                              {variant?.variantType?.it}
                              {/* Variants */}
                            </span>
                            <span className="variant__modal__variant__select__label">
                              Seleccione cualquiera
                            </span>
                          </>
                        )}
                      </div>
                    )}
                    <VariantToggleButtonGroup
                      key={"variant__no__" + index}
                      variant={variant}
                      selectedVariantDetails={selectedVariantDetails}
                      setSelectedVariantDetails={setSelectedVariantDetails}
                      product={product}
                      selectedTempVariantDetails={selectedTempVariantDetails}
                      setSelectedTempVariantDetails={
                        setSelectedTempVariantDetails
                      }
                      multipleVariants={multipleVariants}
                      setMultipleVariants={setMultipleVariants}
                      variantIndex={index}
                      // variantIndex={index}
                      // variantLength={product.variant.length}
                    />
                  </div>
                ))}

                {selectedProductDetails?.recommendedProducts &&
                  selectedProductDetails?.recommendedProducts.length > 0 && (
                    <>
                      {/* <hr className="variant__name__modal__divider !border-b-[2px]" /> */}
                      <div className="variant__modal__recommended__product__details">
                        <span className="variant__modal__variant__name">
                          {storelanguage === "English"
                            ? "Combine your product with"
                            : "Combina tu producto con"}
                        </span>
                        <span className="variant__modal__variant__select__label">
                          {storelanguage === "English"
                            ? "Select if you want"
                            : "Selecciona si quieres"}
                          <span className="variant__modal__variant__select__optional">
                            {storelanguage === "English"
                              ? "(Optional)"
                              : "(Opcional)"}
                          </span>
                        </span>
                      </div>

                      <div className="variant__modal__recommended__product__wrapper">
                        {selectedProductDetails?.recommendedProducts.map(
                          (recommendedProduct, index) => (
                            <RecommendedProduct
                              key={"recommended__product__no" + index}
                              recommendedProduct={recommendedProduct}
                              storeCurrency={storeCurrency}
                              index={index}
                              selectedProductDetails={selectedProductDetails}
                              cart={variantTempDetails}
                              totalTempPrice={totalTempPrice}
                              setTotalTempPrice={setTotalTempPrice}
                              incrementTempVariantCount={
                                incrementTempVariantCount
                              }
                              decrementTempVariantCount={
                                decrementTempVariantCount
                              }
                              calculateQuantity={calculateQuantity}
                              totalPrice={totalPrice}
                            />
                          )
                        )}
                      </div>
                    </>
                  )}

                {selectedProductDetails && (
                  <div className={`variant__modal__add__to__cart__wrapper border-t-[3px] border-t-[var(--theme-primary)]`}>
                    {selectedProductDetails?.variant.length > 0 && (
                      <div className="variant__modal__cart__items__change">
                        <span
                          className="variant__modal__variant__minus__btn"
                          onClick={() => {
                            // decrementTempVariantCount(
                            //   selectedProductDetails, variantTempDetails, totalTempPrice, product?.variant?.length !== 0
                            //   ? selectedTempVariantDetails
                            //   : undefined
                            // )
                            decrementVariantCount();
                          }}
                        >
                          -
                        </span>
                        <span className="variant__modal__variant__items__count">
                          {/* {variantTempDetails?.length} */}
                          {variantCount}
                        </span>
                        <span
                          className="variant__modal__variant__plus__btn"
                          onClick={() => {
                            // incrementTempVariantCount(
                            //   selectedProductDetails, variantTempDetails, totalTempPrice, product?.variant?.length !== 0
                            // ? selectedTempVariantDetails
                            // : undefined
                            // )
                            incrementVariantCount();
                          }}
                        >
                          +
                        </span>
                      </div>
                    )}
                    {selectedProductDetails?.variant.length > 0 && (
                      <div className="variant__modal__add__to__cart">
                        <span className="variant__modal__cart__items__price m-[2px]">
                          {currencyFormatter(
                            totalTempPrice.toFixed(2),
                            storeCurrency
                          )}
                        </span>

                        <span
                          className="variant__modal__add__to__cart__text m-[2px]"
                          onClick={() => {
                            if (selectedProductDetails?.variant.length > 0) {
                              addToCartTempVariant(
                                selectedProductDetails,
                                variantTempDetails,
                                totalTempPrice,
                                selectedTempVariantDetails,
                                variantCount,
                                multipleVariants
                              );
                              setOpenVariants(false);
                              setVariantCount(1);
                              setTotalTempPrice(0);
                              setVariantTempDetails([]);
                              setMultipleVariants([]);
                            }
                            // else {
                            //   addToCartTempProduct(
                            //     selectedProductDetails,
                            //     [],
                            //     totalTempPrice
                            //   )
                            //   setOpenVariants(false);
                            //   setVariantCount(1);
                            //   setTotalTempPrice(0);
                            //   setVariantTempDetails([]);
                            // }
                          }}
                        >
                          {storelanguage === "English"
                            ? "Add to Cart"
                            : "añadir a la cesta"}
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal open={incrementVariantsModal}>
        <Box sx={variantIncrementModalStyle}>
          <div className="variants__modal__wrapper">
            <span
              onClick={handleIncrementVariantsClose}
              className="variant__modal__close__btn"
            >
              <RxCross2 className="variant__modal__close__btn__icon" />
            </span>
            <div className="variants__increment__modal__product">
            <div className="variant__modal__product__div">
                <div className="flex gap-[20px] max-xsm:flex-col ">
                  <div className="shadow-[0px_0px_6px_#0000001a] rounded-[10px] min-w-[100px] min-h-[100px] w-[100px] h-[100px]">
                    <img
                      className="variants__modal__product__image p-[10px]"
                      src={selectedProductDetails?.image}
                      alt="product__image"
                    />
                  </div>
                  <div className="variants__modal__product___details">
                    <span className="variants__modal__product___name">
                      {storelanguage === "English" ? (
                        <>
                          {selectedProductDetails &&
                            selectedProductDetails.en.name}
                        </>
                      ) : (
                        <>
                          {selectedProductDetails &&
                            selectedProductDetails.it.name}
                        </>
                      )}
                      {/* Mini Croissants Combo - 4 units */}
                    </span>
                    <span className="variants__modal__product___price">
                      {currencyFormatter(
                        selectedProductDetails?.price.toFixed(2),
                        storeCurrency
                      )}
                    </span>
                    <div className="variants__modal__product___ingredients">
                      {product?.ingredients.map((ingredient, index) => (
                        <img
                          key={"variant__ingredients__no__" + index}
                          className="variants__modal__product__ingredients__image"
                          src={`${ingredient.image}`}
                          alt="ingredients_icon"
                        />
                      ))}
                    </div>
                  </div>
                </div>
                {/* {selectedProductDetails?.variant.length === 0 && (
                  <div
                    className="flex items-center justify-center px-4 py-2 bg-[var(--theme-primary)] text-white font-bold rounded-md cursor-pointer text-sm"
                    onClick={() => {
                      addToCartTempProduct(
                        selectedProductDetails,
                        [],
                        totalTempPrice
                      );
                      setOpenVariants(false);
                      setVariantCount(1);
                      setTotalTempPrice(0);
                      setVariantTempDetails([]);
                    }}
                  >
                    {storelanguage === "English"
                      ? "Add to Cart"
                      : "añadir a la cesta"}
                  </div>
                )} */}
              </div>
              {/* <div className="variant__modal__product__add__div">
                <img
                  className="variants__increment__modal__product__image"
                  src={selectedProductDetails?.image}
                  alt="product__image"
                />
                <div className="variants__modal__product___details">
                  <span className="variants__modal__product___name">
                    {storelanguage === "English" ? (
                      <>
                        {selectedProductDetails &&
                          selectedProductDetails.en.name}
                      </>
                    ) : (
                      <>
                        {selectedProductDetails &&
                          selectedProductDetails.it.name}
                      </>
                    )}
                   
                  </span>
                  <span className="variants__modal__product___price">
                    {currencyFormatter(
                      selectedProductDetails?.price.toFixed(2),
                      storeCurrency
                    )}
                  </span>
                  <div className="variants__modal__product___ingredients">
                    {product?.ingredients.map((ingredient, index) => (
                      <img
                        key={"variant__ingredients__no__" + index}
                        className="variants__modal__product__ingredients__image"
                        src={`${ingredient.image}`}
                        title={ingredient.name}
                        alt="ingredients_icon"
                      />
                    ))}
                  </div>
                </div>
              </div> */}
              <hr className="variant__increment__modal__divider !border-b-[2px]" />
              <div className="variant__increment__modal__variant__details__wrapper px-[30px] pb-[30px]">
                <div className={`variant__increment__modal__variant__details`}>
                  <span className="variant__increment__modal__variant__name">
                    Your Added Variant
                  </span>
                </div>
                {cart && (
                  <CalculateVariantsQuantity
                    cartItems={cart.reduce((newCartItem, oldCartItem) => {
                      if (Array.isArray(Object.values(oldCartItem)[0]) && Object.keys(oldCartItem)[0] === selectedProductDetails?._id) {
                        newCartItem.push(Object.values(oldCartItem)[0][0]);
                      }
                      return newCartItem;
                    }, [])}
                    setIncrementVariantsModal={setIncrementVariantsModal}
                  />
                )}
              </div>
            </div>
            <div
              className="variant__increment__modal__add__new__variant__wrapper"
              onClick={() => {
                setIncrementVariantsModal(false);
                setOpenVariants(true);
                setTotalTempPrice(selectedProductDetails?.price);
              }}
            >
              <span className="variant__increment__modal__add__new__variant__or__text">
                {storelanguage === "English" ? "OR" : "O"}
              </span>
              <span className="variant__increment__modal__add__new__variant__label">
                {storelanguage === "English"
                  ? "Add New Variant"
                  : "Agregar nueva variante"}
              </span>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ProductDetails;
