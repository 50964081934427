import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Header from './Header'
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { DecrementCount, IncrementCount } from '../redux/Actions';
import { relayitemslist } from '../Data/ItemList'
import Product from './Product/Product';
import { useTranslation } from "react-i18next";
import Footer from './Footer';


function ItemsList() {
    const checkvar=true
    const [totalItems, settotalItems]=useState(0)
    const [totalcost,settotalcost] =useState(0)
    const data = useLocation()
    const navigate= useNavigate()
    const dispatch =useDispatch()
    const cartData=[]  
    const countdata=useSelector(state=> state.ItemCounter)
    const listdata=useSelector(state=> state.ItemsListReducer)
    const handleNextButton=()=>{
        cartData.push([{
            count:count1,
            itemName:'Regular Coffee',
            itemImage:'/assets/images/image_dummy.png'    
        }, {
           count:count2,
           itemName:'Chips',
           itemImage:'/assets/images/chips.jpg'        
        },{
          count:count3,
          itemName:'Water',
          itemImage:'/assets/images/voss_water.jpg' 
        },{
           count:count4,
           itemName:'Diet Coke',
           itemImage:'/assets/images/diet_coke.jpg' 
        }, {
            count:count5,
            itemName:'Coke',
            itemImage:'/assets/images/coke.jpg'        
         },{
           count:count6,
           itemName:'Sprite',
           itemImage:'/assets/images/sprite.jpg' 
         },{
            count:count7,
            itemName:'Sweet Snack',
            itemImage:'/assets/images/image_dummy.png' 
         }
    ])
    setTimeout(()=>{
        navigate('/selectDetails', {state:cartData})
        //  window.location.reload()
    },1000)
   }
   const [count1, setcount1] =useState(0)
   const [count2, setcount2] =useState(0)
   const [count3, setcount3] =useState(0)
   const [count4, setcount4] =useState(0) 
   const [count5, setcount5] =useState(0)
   const [count6, setcount6] =useState(0)
   const [count7, setcount7] =useState(0) 

    const { t } = useTranslation()     
    return (
    <> 
     <div className='MainWhole-wrapper-container'>  
    <Header arrow={false}/>
    {console.log('countdata',countdata)}
    <div className='ItemListMain-Wrapper'>
       
        <div className="ItemList_page_Content_wrapper"> 
           <h1> Hello</h1>
            <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus, corrupti ad excepturi voluptatibus maiores nihil!.</p>
        </div>
        <div className="ItemsListPage-ItemsList-wrapper-whole"> 
                 <h2> Order Now</h2>   
                            <div>
                           
                            <div className="ItemsListPage-ItemsList-containers">       
                                <div className='product-content-wrapper'> 
                                                <div className='product-content-wrapper-inner-div-one'> 
                                                <img src='/assets/images/voss_water.jpg' className='product-images-class'/>
                                                </div>
                                                <div className='product-content-wrapper-inner-div-two'>
                                                    <h4> Water</h4>
                                                    <p> Lorem ipsum dolor sit amet consectetur</p>
                                                </div>
                                </div>
                            <div className='ItemsListPage-ItemsList-Counter-wrapper'> 
                              <button className='ItemsListPage-ItemsList-Counter-btn' onClick={()=>{
                                 if(count3>0){setcount3(count3=> count3 - 1)}
                                } 
                            }> -</button>
                              <input type="number" readOnly value={count3} className="ItemsListPage-ItemsList-Counter-input"/>
                               <button className='ItemsListPage-ItemsList-Counter-btn'  onClick={()=>{
                                 setcount3(count3=> count3+ 1)
                                }}> +</button>  
                            </div>
                         </div>
                         <div className="ItemsListPage-ItemsList-containers">       
                                <div className='product-content-wrapper'> 
                                                <div className='product-content-wrapper-inner-div-one'> 
                                                <img src='/assets/images/coke.jpg' className='product-images-class'/>
                                                </div>
                                                <div className='product-content-wrapper-inner-div-two'>
                                                    <h4> Coke</h4>
                                                    <p> Lorem ipsum dolor sit amet consectetur</p>
                                                </div>
                                </div>
                            <div className='ItemsListPage-ItemsList-Counter-wrapper'> 
                              <button className='ItemsListPage-ItemsList-Counter-btn' onClick={()=>{
                                 if(count5>0){setcount5(count5=> count5 - 1)}
                                } 
                            }> -</button>
                              <input type="number" readOnly value={count5} className="ItemsListPage-ItemsList-Counter-input"/>
                               <button className='ItemsListPage-ItemsList-Counter-btn'  onClick={()=>{
                                 setcount5(count5=> count5+ 1)
                                }}> +</button>  
                            </div>
                         </div>
                         <div className="ItemsListPage-ItemsList-containers">       
                                <div className='product-content-wrapper'> 
                                                <div className='product-content-wrapper-inner-div-one'> 
                                                <img src='/assets/images/diet_coke.jpg' className='product-images-class'/>
                                                </div>
                                                <div className='product-content-wrapper-inner-div-two'>
                                                    <h4> Diet Coke</h4>
                                                    <p> Lorem ipsum dolor sit amet consectetur</p>
                                                </div>
                                </div>
                            <div className='ItemsListPage-ItemsList-Counter-wrapper'> 
                              <button className='ItemsListPage-ItemsList-Counter-btn' onClick={()=>{
                                 if(count4>0){setcount4(count4=> count4 - 1)}
                                } 
                            }> -</button>
                              <input type="number" readOnly value={count4} className="ItemsListPage-ItemsList-Counter-input"/>
                               <button className='ItemsListPage-ItemsList-Counter-btn'  onClick={()=>{
                                 setcount4(count4=> count4+ 1)
                                }}> +</button>  
                            </div>
                         </div>
                              
                         <div className="ItemsListPage-ItemsList-containers">       
                                <div className='product-content-wrapper'> 
                                                <div className='product-content-wrapper-inner-div-one'> 
                                                <img src='/assets/images/sprite.jpg' className='product-images-class'/>
                                                </div>
                                                <div className='product-content-wrapper-inner-div-two'>
                                                    <h4> Sprite</h4>
                                                    <p> Lorem ipsum dolor sit amet consectetur</p>
                                                </div>
                                </div>
                            <div className='ItemsListPage-ItemsList-Counter-wrapper'> 
                              <button className='ItemsListPage-ItemsList-Counter-btn' onClick={()=>{
                                 if(count6>0){setcount6(count6=> count6 - 1)}
                                } 
                            }> -</button>
                              <input type="number" readOnly value={count6} className="ItemsListPage-ItemsList-Counter-input"/>
                               <button className='ItemsListPage-ItemsList-Counter-btn'  onClick={()=>{
                                 setcount6(count6=> count6+ 1)
                                }}> +</button>  
                            </div>
                         </div>
                            <div className="ItemsListPage-ItemsList-containers">       
                                <div className='product-content-wrapper'> 
                                                <div className='product-content-wrapper-inner-div-one'> 
                                                <img src='/assets/images/image_dummy.png' className='product-images-class'/>
                                                </div>
                                                <div className='product-content-wrapper-inner-div-two'>
                                                    <h4> Regular Coffee</h4>
                                                    <p> Lorem ipsum dolor sit amet consectetur</p>
                                                </div>
                                </div>
                            <div className='ItemsListPage-ItemsList-Counter-wrapper'> 
                              <button className='ItemsListPage-ItemsList-Counter-btn' onClick={()=>{
                                 if(count1>0){setcount1(count1=> count1 - 1)}
                                } 
                            }> -</button>
                              <input type="number" readOnly value={count1} className="ItemsListPage-ItemsList-Counter-input"/>
                               <button className='ItemsListPage-ItemsList-Counter-btn'  onClick={()=>{
                                 setcount1(count1=> count1+ 1)
                                }}> +</button>  
                            </div>
                         </div>
               
                         <div className="ItemsListPage-ItemsList-containers">       
                                <div className='product-content-wrapper'> 
                                                <div className='product-content-wrapper-inner-div-one'> 
                                                <img src='/assets/images/image_dummy.png' className='product-images-class'/>
                                                </div>
                                                <div className='product-content-wrapper-inner-div-two'>
                                                    <h4> Sweet Snack</h4>
                                                    <p> Lorem ipsum dolor sit amet consectetur</p>
                                                </div>
                                </div>
                            <div className='ItemsListPage-ItemsList-Counter-wrapper'> 
                              <button className='ItemsListPage-ItemsList-Counter-btn' onClick={()=>{
                                 if(count7>0){setcount7(count7=> count7 - 1)}
                                } 
                            }> -</button>
                              <input type="number" readOnly value={count7} className="ItemsListPage-ItemsList-Counter-input"/>
                               <button className='ItemsListPage-ItemsList-Counter-btn'  onClick={()=>{
                                 setcount7(count7=> count7+ 1)
                                }}> +</button>  
                            </div>
                         </div>
                         <div className="ItemsListPage-ItemsList-containers">       
                                <div className='product-content-wrapper'> 
                                                <div className='product-content-wrapper-inner-div-one'> 
                                                <img src='/assets/images/chips.jpg' className='product-images-class'/>
                                                </div>
                                                <div className='product-content-wrapper-inner-div-two'>
                                                    <h4> Chips</h4>
                                                    <p> Lorem ipsum dolor sit amet consectetur</p>
                                                </div>
                                </div>
                            <div className='ItemsListPage-ItemsList-Counter-wrapper'> 
                              <button className='ItemsListPage-ItemsList-Counter-btn' onClick={()=>{
                                 if(count2>0){setcount2(count2=> count2 - 1)}
                                } 
                            }> -</button>
                              <input type="number" readOnly value={count2} className="ItemsListPage-ItemsList-Counter-input"/>
                               <button className='ItemsListPage-ItemsList-Counter-btn'  onClick={()=>{
                                 setcount2(count2=> count2+ 1)
                                }}> +</button>  
                            </div>
                         </div>
                         </div>
       
          
        </div>
   
    </div>
    {count1>0 || count2>0 || count3>0 || count4>0 || count5>0|| count6>0|| count7>0   ?<button className="ItemList_Page_Next_button" onClick={handleNextButton}> Next </button>:null }
     
    <Footer/>
   </div> 
   </>
  )
}

export default ItemsList