import React from "react";

function Footer(props) {
  return (
    <div
      className={
        props.open || props.opentwo
          ? "Footer_Content_wrapper_copy"
          : "Footer_Content_wrapper"
      }
    >
      <p className="!text-[12px]"> Powered By</p>
      <img className="h-[20px] self-center" src="/assets/images/ottonomy-svg.png" alt="ottonomy_logo" />
    </div>
  );
}

export default Footer;
