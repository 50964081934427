import { useState, useEffect } from "react";
import { FiNavigation2 } from "react-icons/fi";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useNavigate } from "react-router-dom";

function Mapbox(props) {
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(15);
  const [robotMarker, setRobotMarker] = useState(null);
  const [dropMarker, setDropMarker] = useState(null);
  const [pickupMarker, setPickupMarker] = useState(null);
  const [dropLocationPopup, setDropLocationPopup] = useState(null);
  const [userControllingMap, setUserControllingMap] = useState(false);
  const [temp, setTemp] = useState(false);

  const navigate = useNavigate();
  mapboxgl.accessToken =
    "pk.eyJ1IjoibXJsYWtzaHlhZ3VwdGEiLCJhIjoiY2xpZWZlcTJjMGo4ZDNkczBwc2ZmcW1qMyJ9.Kurk7BS04invJcCwS0m4Pg";

  // robotMarkerDiv.style.backgroundImage =
  //   "url(/assets/images/robot-icon-main.svg)";

  const dropMarkerDiv = document.createElement("div");
  dropMarkerDiv.style.width = "20px";
  dropMarkerDiv.style.height = "20px";
  dropMarkerDiv.style.backgroundImage = "url(/assets/images/dropoff.svg)";
  // dropMarkerDiv.style.backgroundImage = "url(/assets/images/robot_dropOff_main.svg)";
  dropMarkerDiv.style.borderRadius = "100%";

  window.deliveryNavigate = (
    _id,
    mapId,
    locationName,
    metricPositionX,
    metricPositionY,
    metricPositionYaw,
    positionX,
    positionY,
    positionYaw,
    latitude,
    longitude,
    status,
    locationType,
    imageLocationUrl,
    homeRobot,
    headingAngle,
    createdAt,
    updatedAt,
    __v,
    locationId,
    spanishLocationName,
    group
    // deliveryLocationData
    ) => {
      // console.log(JSON.parse(deliveryLocationData))
      if(props.storelanguage === "English") {
        sessionStorage.setItem("deliveryLocation", locationName);
        localStorage.setItem("deliveryLocation", locationName);
      } else {
        sessionStorage.setItem("deliveryLocation", spanishLocationName);
        localStorage.setItem("deliveryLocation", spanishLocationName);
      }
      
    navigate("/details", {
      state: {
        locationName: {
          _id: _id,
          mapId: mapId,
          locationName: locationName,
          metricPositionX: metricPositionX,
          metricPositionY: metricPositionY,
          metricPositionYaw: metricPositionYaw,
          positionX: positionX,
          positionY: positionY,
          positionYaw: positionYaw,
          latitude: latitude,
          longitude: longitude,
          status: status,
          locationType: locationType,
          imageLocationUrl: imageLocationUrl,
          homeRobot: homeRobot,
          headingAngle: headingAngle,
          createdAt: createdAt,
          updatedAt: updatedAt,
          locationId: locationId,
          spanishLocationName: spanishLocationName,
          group: group
        },
        cartdata: props.cartData.state.stateone,
        statetwo: props.cartData?.state?.statetwo,
        totalPrice: props.cartData?.state?.totalPrice,
        deliveryCharge: props.cartData?.state?.deliveryCharge,
        totaltax: props.cartData?.state?.totaltax,
        storeId: props.cartData?.state?.statetwo?.storeId,
      },
    });
  };

  const initializeMap = () => {
    const newMap = new mapboxgl.Map({
      container: "map-container-id",
      style: "mapbox://styles/mrlakshyagupta/climr57of00le01pgc0jjgfoh",
      // style: "mapbox://styles/mrlakshyagupta/clmribzic029m01qx4biqdw6s",
      // center: [
      //   props?.robotLocation?.longitudeSlam,
      //   props?.robotLocation?.latitudeSlam,
      // ],
      zoom: 5,
      // maxBounds: [
      //   [-95.55892625673143, 29.77473436824925],
      //   [-95.52934215261119, 29.788676939812166],
      // ],
      maxBounds: [[props?.maxbounds?.southwest?.lng,props?.maxbounds?.southwest?.lat],[props?.maxbounds?.northeast?.lng,props?.maxbounds?.northeast?.lat]]
    });

    newMap.on("wheel", () => {
      setUserControllingMap(true);
    });

    newMap.on("drag", () => {
      setUserControllingMap(true);
    });

    newMap.on("move", () => {
      setZoom(newMap.getZoom().toFixed(2));
    });

    newMap.on("idle", function () {
      newMap.resize();
    });

    newMap.on("style.load", function () {
      const waiting = () => {
        if (!newMap.isStyleLoaded()) {
          // console.log("Timeout")
          setTimeout(waiting, 200);
        } else {
          setMap(newMap);
          updateMap();
        }
      };
      waiting()
    })

    // setUserControllingMap(true)
  };

  const updateMap = () => {
    if(map) {
      const deliveryLocationMarker = map.hasImage("deliveryLocation-marker");
        !deliveryLocationMarker &&
          map.loadImage(
            `${process.env.PUBLIC_URL}/assets/images/circleMarker.png`,
            (error, image) => {
              if (error) throw error;
              map.addImage("deliveryLocation-marker", image);
            }
          );
  
        !map.getSource("deliveryPoints-0") &&
          props?.deliveryLocations?.map((data, index) => {
            if (data.status) {
              map.addSource(`deliveryPoints-${index}`, {
                type: "geojson",
                data: {
                  type: "FeatureCollection",
                  name: `Delivery-Point-${index}`,
                  features: [
                    {
                      type: "Feature",
                      properties: {
                        title: props.storelanguage === "English" ? data.locationName : data.spanishLocationName,
                        description: `
                          <div><strong>Name: </strong><span>${props.storelanguage === "English" ? data.locationName : data.spanishLocationName}</span></div>
                         `,
                      },
                      geometry: {
                        type: "Point",
                        coordinates: [data.longitude, data.latitude],
                      },
                    },
                  ],
                },
              });
            }
  
            return null;
          });
  
        !map.getLayer("deliveryPointsLayer-0") &&
          props?.deliveryLocations?.map((data, index) => {
            if (data.status) {
              // let tempData = JSON.stringify(data)
              // console.log(tempData)
              // console.log(typeof tempData)
              map.addLayer({
                id: `deliveryPointsLayer-${index}`,
                type: "symbol",
                source: `deliveryPoints-${index}`,
                layout: {
                  "icon-image": "deliveryLocation-marker",
                  "icon-size": 0.15,
                  "icon-allow-overlap": true,
                  "icon-rotate": parseFloat(data.headingAngle),
                  "icon-rotation-alignment": "map",
                  "text-allow-overlap": true,
                  "text-ignore-placement": true,
                  "icon-ignore-placement": true,
                  // 'text-field': ['get', 'title'],
                  // 'text-font': [
                  // 'Open Sans Semibold',
                  // 'Arial Unicode MS Bold'
                  // ],
                  // 'text-size': 20,
                  // 'text-offset': [0, 1.25],
                  // 'text-anchor': 'top',
                  // 'text-color': '#ffffff',
                },
                paint: {
                  "icon-opacity": 1,
                },
                filter: ["==", "$type", "Point"],
              });
              // When a click event occurs on a feature in the places layer, open a popup at the
              // location of the feature, with description HTML from its properties.
              new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false,
                offset: 10,
              })
                .setLngLat([data.longitude, data.latitude])
                .setHTML(
                  `<div class="location__name__popup" onclick="deliveryNavigate('${data._id}','${data.mapId}','${data.locationName}','${data.metricPositionX}','${data.metricPositionY}','${data.metricPositionYaw}','${data.positionX}','${data.positionY}','${data.positionYaw}','${data.latitude}','${data.longitude}','${data.status}','${data.locationType}','${data.imageLocationUrl}','${data.homeRobot}','${data.headingAngle}','${data.createdAt}','${data.updatedAt}','${data.__v}','${data.locationId}','${data.spanishLocationName}','${data.group}')"><span class="text-[var(--theme-primary)] font-bold">${props.storelanguage === "English" ? data?.locationName?.split("_")
                  .join(" ") : data?.spanishLocationName?.split("_")
                  .join(" ")}</span></div>`
                )
                // .setHTML(
                //   '<div class="location__name__popup" onclick="deliveryNavigate(' +JSON.stringify(data)+ ')"><span class="text-[var(--theme-primary)] font-bold">'+data?.locationName?.split("_").join(" ")+'</span></div>'
                // )
                .addTo(map);
  
              map.on("click", `deliveryPointsLayer-${index}`, (e) => {
                // Copy coordinates array.
                // const coordinates = e.features[0].geometry.coordinates.slice();
                // const locationName = e.features[0].properties.title;
                
                if(props.storelanguage === "English") {
                  sessionStorage.setItem("deliveryLocation", data.locationName);
                  localStorage.setItem("deliveryLocation", data.locationName);
                } else {   
                  sessionStorage.setItem("deliveryLocation", data.spanishLocationName);
                  localStorage.setItem("deliveryLocation", data.spanishLocationName);
                }
  
                navigate("/details", {
                  state: {
                    locationName: data,
                    cartdata: props.cartData.state.stateone,
                    statetwo: props.cartData?.state?.statetwo,
                    totalPrice: props.cartData?.state?.totalPrice,
                    deliveryCharge: props.cartData?.state?.deliveryCharge,
                    totaltax: props.cartData?.state?.totaltax,
                    storeId: props.cartData?.state?.statetwo?.storeId,
                  },
                });
                // Ensure that if the map is zoomed out such that multiple
                // copies of the feature are visible, the popup appears
                // over the copy being pointed to.
              });
  
              // Change the cursor to a pointer when the mouse is over the places layer.
              map.on("mouseenter", `deliveryPointsLayer-${index}`, () => {
                map.getCanvas().style.cursor = "pointer";
              });
  
              // Change it back to a pointer when it leaves.
              map.on("mouseleave", `deliveryPointsLayer-${index}`, () => {
                map.getCanvas().style.cursor = "";
              });
            }
            return null;
          });
  
        if (dropMarker) {
          dropMarker
            .setLngLat([
              parseFloat(props?.orderDropLocation?.longitude)
                ? parseFloat(props?.orderDropLocation?.longitude)
                : 0,
              parseFloat(props?.orderDropLocation?.latitude)
                ? parseFloat(props?.orderDropLocation?.latitude)
                : 0,
            ])
            .setRotationAlignment("map");
  
          // Set the content of the popup
          dropLocationPopup.setHTML(
            `<div class="custom-popup"><p>${
              props?.orderDropLocation?.locationName.includes("_")
                ? props?.orderDropLocation?.locationName
                    .split("_")
                    .slice(1)
                    .join(" ")
                : props?.orderDropLocation?.locationName
            }</p></div>`
          );
  
          // Add the popup to the map at the specified coordinates
          dropLocationPopup.setLngLat([
            props?.orderDropLocation?.longitude
              ? props?.orderDropLocation?.longitude
              : 0,
            props?.orderDropLocation?.latitude
              ? props?.orderDropLocation?.latitude
              : 0,
          ]);
        }
  
        if (!userControllingMap && props?.deliveryLocations?.length) {
          // Create a 'LngLatBounds' with both corners at the first coordinate.
          const bounds = new mapboxgl.LngLatBounds();
  
          // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
          // let newDeliverLocationArray = props.deliveryLocations.map(
          //   (data) => {
          //     return {
          //       lon: data.longitude,
          //       lat: data.latitude,
          //     };
          //   }
          // )
          // console.log(newDeliverLocationArray)
          for (const coord of props?.deliveryLocations) {
            bounds.extend({
              lon: parseFloat(coord.longitude),
              lat: parseFloat(coord.latitude),
            });
          }
          // bounds.extend([
          //   props?.orderDropLocation?.longitude,
          //   props?.orderDropLocation?.latitude,
          // ]);
  
          // bounds.extend([
          //   props?.orderDropLocation?.longitude,
          //   props?.orderDropLocation?.latitude,
          // ]);
  
          // if (destinationMarkerForBound.length !== 0) {
          //   bounds.extend(destinationMarkerForBound);
          // }
  
          map.fitBounds(bounds, {
            padding: 100,
            duration: 200,
            // bearing: robotDetails[robotId].headingSlam
            //   ? parseFloat(robotDetails[robotId].headingSlam)
            //   : 0,
          });
        }
    }
  }

  useEffect(() => {
    // if(props?.deliveryLocations?.length) {
      if (!map) {
        initializeMap();
      }
    // }
  }, [map, setMap, props.storelanguage, props.deliveryLocations]);

  useEffect(() => {
    // move robot marker
    // debugger
    if (map && map.isStyleLoaded()) {
      updateMap()
    } 
  }, [
    dropMarker,
    navigate,
    props,
    props.deliveryLocations,
    userControllingMap,
    map,
    props.orderDropLocation,
    dropLocationPopup,
    props.showDeliveryType,
    props.storelanguage
  ]);

  useEffect(() => {
    // if(map) {
      if(map && map.getLayer("deliveryPointsLayer-0")) {
        props.deliveryLocations.map((location,index) => {
          map.removeLayer(`deliveryPointsLayer-${index}`)
          return null;
        })
      }
      if(map && map.getSource("deliveryPoints-0")) {
        props.deliveryLocations.map((location,index) => {
          map.removeSource(`deliveryPoints-${index}`)
          return null;
        })
      }
      updateMap()
    // }
  },[map, props.deliveryLocations, props.storelanguage])

  return (
    <>
      {userControllingMap &&
        !!Number(process.env.REACT_APP_USER_MAP_CONTROL) && (
          <div
            className={`cursor-pointer flex items-center gap-2 sidebar bg-[var(--theme-primary)] text-white p-[6px_12px] z-[1] absolute bottom-3 left-1 rounded-[4px] h-[30px]`}
            onClick={() => setUserControllingMap(false)}
          >
            <FiNavigation2 strokeWidth={3} />
            <span className="text-xs font-bold uppercase">Re-centre</span>
          </div>
        )}
      <div
        id="map-container-id"
        className="absolute w-full h-full map-container"
      ></div>
    </>
  );
}

export default Mapbox;
