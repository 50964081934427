import React from 'react'
import Header from './Header'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
function MoreDetails() {
 
  const { storelanguage } =useSelector(state=>state.storelanguagereducer)
 

    const {t}= useTranslation()  
    return (
    <>
     <div className='MainWhole-wrapper-container'>
    <Header arrow={false}/>
    <div className='MoreDetails_page_wrapper'>
    <div className="MoreDetials_page_Banner_wrapper"> 
        <img src="/assets/images/Shop_to_gate.svg" alt=""/>
        <img src="/assets/images/ARD_Logo.png" alt=""/>
     </div>
      <div style={{backgroundColor:"white"}}> 
        <h2 className="More_Details_Page_h2">  {storelanguage==='English'? "More Detail" :"Più dettaglio"}</h2>
        {storelanguage==='English'? 
        <div> 
            <div style={{marginTop:'0px', padding:'20px'}}>   
              <h5 className="More_Details_Page_h5"> {t('More_Details_page_content_h5_1')}</h5>
              <p className="More-details-page-content-p"> {t('More_Details_page_content_p_1')}</p>
            </div>
              <div style={{marginTop:'0px', padding:'20px'}}> 
              <h5 className="More_Details_Page_h5">{t('More_Details_page_content_h5_2')} </h5>
              <p className="More-details-page-content-p"> {t('More_Details_page_content_p_2')}</p>
            </div>
            <div style={{marginTop:'0px', padding:'20px'}}>
              <h5 className="More_Details_Page_h5"> {t('More_Details_page_content_h5_3')}</h5>
              <p className="More-details-page-content-p"> {t('More_Details_page_content_p_3')}
                  <p className="More-details-page-content-p"> {t('More_Details_page_content_p_4')}  </p>
                  <p className="More-details-page-content-p"> {t('More_Details_page_content_p_5')} </p>
                  <p className="More-details-page-content-p"> {t('More_Details_page_content_p_6')} </p>
                  <p className="More-details-page-content-p"> {t('More_Details_page_content_p_7')} </p>
                  <p className="More-details-page-content-p"> {t('More_Details_page_content_p_8')}  </p>
                </p>
            </div> 
            <div style={{marginTop:'0px', padding:'20px'}}>
              <h5 className="More_Details_Page_h5"> {t('More_Details_page_content_h5_4')}</h5>
              <p className="More-details-page-content-p"> {t('More_Details_page_content_p_9')}</p>
            </div>
        </div>: 
         <div> 
         <div style={{marginTop:'0px', padding:'20px'}}>   
           <h5 className="More_Details_Page_h5"> Sai che puoi ricevere i tuoi acquisti comodamente al gate?</h5>
           <p className="More-details-page-content-p"> Da oggi nel nuovo molo A dell’aeroporto di Roma Fiumicino è possibile pagare online e ricevere direttamente al gate di imbarco i tuoi acquisti, sarà il nostro ottobot ad effettuare la consegna!</p>
         </div>
           <div style={{marginTop:'0px', padding:'20px'}}> 
           <h5 className="More_Details_Page_h5">Come funziona il servizio «Shop to gate»? </h5>
           <p className="More-details-page-content-p"> Una volta inquadrato il QR code, seleziona la tua lingua ed entra in uno dei due shop «virtuali» Aelia o Relay, puoi selezionare i prodotti direttamente dal tuo smartphone.</p>
         </div>
         <div style={{marginTop:'0px', padding:'20px'}}>
           <h5 className="More_Details_Page_h5"> Come faccio a pagare e ritirare i prodotti?</h5>
           <p className="More-details-page-content-p"> Dopo che hai selezionato i prodotti, dovrai scegliere il punto di consegna più vicino a te. Ottobot ha 4 stazioni di consegna nel molo A, individua quella più vicina al tuo gate di imbarco:
               <p className="More-details-page-content-p"> Gate A61 </p>
               <p className="More-details-page-content-p"> Gate A62-A65</p>
               <p className="More-details-page-content-p"> Gate A66-A69</p>
               <p className="More-details-page-content-p"> Gate A70-A73 </p>
               <p className="More-details-page-content-p">Finalizza l’acquisto con le tue informazioni personali e con i dati della tua carta di credito o debito. Riceverai un QR code che mostrerai direttamente ad Ottobot quando sarà arrivato alla stazione di consegna. Solo tu potrai ritirare i tuoi prodotti!  </p>
             </p>
         </div> 
         <div style={{marginTop:'0px', padding:'20px'}}>
           <h5 className="More_Details_Page_h5"> Non sono riuscito a ritirare i prodotti acquistati, che posso fare?</h5>
           <p className="More-details-page-content-p"> Se per qualunque motivo non riuscissi a ritirare i prodotti prima dell’imbarco, potrai chiedere il rimborso totale della spesa effettuata tramite la webapp, accedendo alla sezione «….». 
Puoi anche inoltrare una mail all’indirizzo «…» per richiedere assistenza.</p>
         </div>
     </div>
        }
     </div> 
    </div>
     
  </div> 
    </>
  )
}

export default MoreDetails