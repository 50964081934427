import React from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { orderbooking } from "../API";
import Footer from "./Footer";
import HeaderRedirecting from "./HeaderRedirecting";

function Redirecting() {
  const data = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.location.replace(
        `https://quickpay.hmshost.com/menu/221/208?orderid=${data.state.randomorderId}`
      );
      //  window.location.replace(`http://localhost:3003/?orderid=${data.state.randomorderId}`)
    }, 3000);
  }, []);
  useEffect(() => {
    if (
      !data?.state ||
      data?.state === undefined ||
      data?.state === null ||
      data?.state === "null"
    ) {
      window.location.replace("/");
    }
  }, [data?.state]);

  return (
    <div className="MainWhole-wrapper-container">
      {/* {console.log('data',data)} */}
      <HeaderRedirecting />
      <div className="Redirecting_page_content_wrapper">
        <div className="Redirecting_page_content_wrapper_div_one">
          <img src="/assets/images/Loader.svg" alt="" />
        </div>
        <div className="Redirecting_page_content_wrapper_div_two">
          <img src="/assets/images/Spinner-1s-200px.svg" alt="" />
        </div>
        <div className="Redirecting_page_content_wrapper_div_three">
          <p> Redirecting to the ordering App</p>
          <img src="/assets/images/HMS_Logo.png" alt="" />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Redirecting;
