import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ETAtime,
  getlocationlist,
  // getlocationlistmapIdv2,
  getmapDetialmapId,
  getproductlist,
  getstorelist,
} from "../API";
import { SliderData } from "../Data/Storelist";
import Footer from "./Footer";
import Header from "./Header";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { useDispatch, useSelector } from "react-redux";
import { storecurrency, storemapIdaction, clearCart } from "../redux/Actions";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import CircularProgress from "@mui/material/CircularProgress";

function StoreList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setloader] = useState(true);
  const handlestoreclick = (item) => {
    let storeId = item.storeId;
    if (item.type === "hms") {
      dispatch(storecurrency(item.currency));
      dispatch(storemapIdaction(item.mapId));
      navigate("/selectDetails", { state: { storeId, item } });
      localStorage.setItem("store_type", "hms");
    } else {
      dispatch(clearCart());
      localStorage.clear();
      sessionStorage.clear();
      dispatch(storecurrency(item.currency));
      dispatch(storemapIdaction(item.mapId));
      navigate(`/storelist/${item.storeId}`, { state: { item: item } });
      localStorage.setItem("store_type", "self");
    }
  };
  const [storelist, setstorelist] = useState([]);
  // const [mapName, setmapName] = useState();
  const { storelanguage } = useSelector((state) => state.storelanguagereducer);
  const { mapId } = useSelector((state) => state.storemapIdreducer);

  useEffect(() => {
    getstorelist()
      .then((res) => {
        // console.log('Storelist response',res)
        setloader(false);
        setstorelist(
          res.data.message
          // ?.sort((a, b) => {
          //   const nameA = a.storeId.toLowerCase();
          //   const nameB = b.storeId.toLowerCase();
          //   if (nameA < nameB) {
          //     return 1;
          //   }
          //   if (nameA > nameB) {
          //     return -1;
          //   }
          //   return 0;
          // })
        );
      })
      .catch((err) => {
        //  console.log(err)
      });
    // getlocationlistmapIdv2(mapId)
    // getlocationlistmapId(storeId)
    //   .then((res) => {
    //     //  console.log('getlocationlistmapId response', res)
    //     setmapName(res.data.data.mapName);
    //   })
    //   .catch((err) => {});
  }, []);
  const [ETAarray, setETAarray] = useState([]); // Initialize ETAarray with an empty array
  useEffect(() => {
    const promises = storelist?.map((item) => {
      return ETAtime(item.storeId)
        .then((res) => {
          return [res.data.message, res.data.service];
        })
        .catch((err) => {
          // console.error(err);
          return null;
        });
    });
    Promise.all(promises)
      .then((results) => {
        // Filter out null values (failed promises) from the results
        const filteredResults = results.filter((message) => message !== null);
        setETAarray(filteredResults); // Update the state with the resolved messages
      })
      .catch((err) => {
        // console.error(err);
      });
  }, [storelist]);
  // console.log('storelanguage',storelanguage)
  return (
    <div className="MainWhole-wrapper-container">
      <Header arrow={process.env.REACT_APP_CHANGE_HOME ? true : false} />
      <div className="ItemListMain-Wrapper">
        <div className="Shop_toGate_wrapper rounded-b-md">
          {/* <img src="/assets/images/Shop_to_gate.svg" alt="" /> */}
          {storelanguage === "English" ? (
            <img
              // src="https://imagedelivery.net/HOqw_0sYZTUgFQ-soBOgtg/a335f140-ccce-45b7-4cc8-8c119443b800/public"
              src={process.env.REACT_APP_STORE_PAGE_COVER_EN}
              alt=""
              className="rounded-b-lg drop-shadow-md"
              style={{
                width: "100%",
              }}
            />
          ) : (
            <img
              // src="https://imagedelivery.net/HOqw_0sYZTUgFQ-soBOgtg/aa58ab2e-a86f-4c8c-0fb2-90052315d500/public"
              src={process.env.REACT_APP_STORE_PAGE_COVER_IT}
              alt=""
              className="rounded-b-lg"
              style={{
                width: "100%",
              }}
            />
          )}
          {/* <p>
            {" "}
            {storelanguage === "English"
              ? "The service is available from Monday to Friday"
              : "Il servizio è disponibile dal lunedì al venerdì"}{" "}
          </p>
          <h6>
            {" "}
            {storelanguage === "English"
              ? "10 AM-1 PM and 3-6 PM"
              : "Venerdì dalle 10 alle 13 e dalle 15 alle 18"}
          </h6>
          <button
            onClick={() => {
              navigate("/moredetails");
            }}
          >
            {" "}
            {storelanguage === "English"
              ? "Click Here to find out more"
              : "Clicca qui per saperne di più"}{" "}
          </button>
          <img src="/assets/images/ARD_Logo.png" alt="" /> */}
        </div>

        <div className="Storelist-container-wrapper">
          {storelanguage === "English" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyItems: "flex-start",
              }}
            >
              <h3 className="!text-[24px]">
                {" "}
                {/* {storelist?.length}{" "} */}
                Place your{" "}
                <span
                  style={{
                    color: "var(--theme-primary)",
                  }}
                >
                  order
                </span>
              </h3>
              <span className="text-[18px] text-left">
                from anywhere on{" "}
                <span
                  style={{
                    color: "var(--theme-primary)",
                    fontWeight: 700,
                  }}
                >
                  campus
                </span>
              </span>
            </div>
          ) : (
            <div className="flex flex-col items-start justify-start">
              <span>
                <div className="flex flex-col items-start justify-start">
                  <h3 className="!text-[24px]">
                    {" "}
                    {/* {storelist?.length}{" "} */}
                    Realiza tu{" "}
                    <span
                      style={{
                        color: "var(--theme-primary)",
                      }}
                    >
                      pedido
                    </span>
                  </h3>
                  <span className="text-[18px] text-left">
                    desde cualquier lugar del{" "}
                    <span
                      style={{
                        color: "var(--theme-primary)",
                        fontWeight: 700,
                      }}
                    >
                      campus
                    </span>
                  </span>
                </div>{" "}
              </span>
            </div>
          )}

          {storelist?.length > 0 ? (
            <>
              {storelist?.map((item, index) => {
                if (ETAarray[index] !== undefined) {
                  return (
                    <Fragment key={"store__" + index}>
                      <div
                        className="Storelist_container_inner_item_wrapper"
                        onClick={() => {
                          item.status === 0 ||
                            item.capacity ||
                            !ETAarray[index][1] ||
                            handlestoreclick(item);
                        }}
                      >
                        <div
                          className={
                            item.status === 0 ||
                            item.capacity ||
                            !ETAarray[index][1]
                              ? "rounded-[7px] bg-white inactive_store_with_drop_shadow p-2.5"
                              : "rounded-[7px] bg-white drop-shadow-[0_0_0.5rem_rgba(0,0,0,0.2)] p-2.5"
                          }
                        >
                          <img
                            alt=""
                            src={item.image}
                            style={{
                              borderRadius: "6px",
                            }}
                          />
                        </div>

                        <div
                          className={`Storelist_container_inner_item_wrapper_content_div`}
                        >
                          {(item.status === 0 ||
                            item.capacity ||
                            !ETAarray[index][1]) && (
                            <div className="flex items-center text-left text-[#E50101] font-light text-[12px] leading-4">
                              <IoIosCheckmarkCircleOutline className="!w-[2rem] !h-[2rem] text-[#E50101]" />
                              {storelanguage === "English"
                                ? process.env.REACT_APP_STORE_TIMINGS_EN
                                : process.env.REACT_APP_STORE_TIMINGS_IT}
                            </div>
                          )}
                          {storelanguage === "English" ? (
                            <h4
                              className={`${
                                item.status === 0 ||
                                item.capacity ||
                                !ETAarray[index][1]
                                  ? "inactive_store !text-[#8f8f8f]"
                                  : ""
                              } !text-[16px]`}
                            >
                              {" "}
                              {item.en.name}
                            </h4>
                          ) : (
                            <h4
                              className={`${
                                item.status === 0 ||
                                item.capacity ||
                                !ETAarray[index][1]
                                  ? "inactive_store !text-[#8f8f8f]"
                                  : ""
                              } !text-[16px]`}
                            >
                              {" "}
                              {item.it.name}
                            </h4>
                          )}
                          {storelanguage === "English" ? (
                            <h6
                              className={`
                            ${
                              item.status === 0 ||
                              item.capacity ||
                              !ETAarray[index][1]
                                ? "inactive_store"
                                : ""
                            } !text-[14px] !leading-5 text-left`}
                              
                            >
                              {" "}
                              {item.en.description}
                            </h6>
                          ) : (
                            <h6
                              className={`${
                                item.status === 0 ||
                                item.capacity ||
                                !ETAarray[index][1]
                                  ? "inactive_store"
                                  : ""
                              } !text-[14px] text-left !leading-5`}
                              
                            >
                              {" "}
                              {item.it.description}
                            </h6>
                          )}
                          {storelanguage === "English" ? (
                            <span
                              className={`${
                                item.status === 0 ||
                                item.capacity ||
                                !ETAarray[index][1]
                                  ? "inactive_store"
                                  : ""
                              } text-[var(--theme-primary)] text-left font-[800] text-[12px] leading-[1.15rem]`}
                            >
                              {" "}
                              {item?.topDiscount?.description?.en}
                            </span>
                          ) : (
                            <span
                              className={`${
                                item.status === 0 ||
                                item.capacity ||
                                !ETAarray[index][1]
                                  ? "inactive_store"
                                  : ""
                              } text-[var(--theme-primary)] text-left font-[800] text-[12px] leading-[1.15rem]`}
                            >
                              {" "}
                              {item?.topDiscount?.description?.it}
                            </span>
                          )}

                          {item.status === 0 ||
                          item.capacity ||
                          !ETAarray[index][1] ? (
                            <>
                              <p className="p_error mt-2 !text-[14px] text-left">
                                {" "}
                                {
                                  storelanguage === "English"
                                    ? process.env.REACT_APP_STORE_UNAVAILABLE_EN
                                    : process.env.REACT_APP_STORE_UNAVAILABLE_IT
                                  // ? "Currently Unavailable"
                                  // : "Actualmente no disponible"
                                }
                              </p>
                              {/* <span></span> */}
                            </>
                          ) : (
                            <>
                              {storelanguage === "English" ? (
                                <span className="shop__now__btn">
                                  Order Now
                                </span>
                              ) : (
                                <span className="shop__now__btn">
                                  haz tu pedido
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </Fragment>
                  );
                }
                return null;
              })}
            </>
          ) : (
            <>
              {" "}
              {loader ? (
                <div className="loader__container">
                  {" "}
                  <CircularProgress className="loader__icon" />
                </div>
              ) : (
                <div className="NoStore_available_text_div">
                  <img src="/assets/images/storeclosed.svg" alt="" />
                  <h4> No Stores Available</h4>{" "}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default StoreList;
