import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import {
  useStripe,
  useElements,
  CardElement,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { orderconfirm } from "../API";
import { useDispatch, useSelector } from "react-redux";
import { clearCart, confirmedCart } from "../redux/Actions";

export default function CheckoutForm(props) {
  const { cart, totalitems, totalPrice } = useSelector((state) => state.IC);
  const { storelanguage } = useSelector((state) => state.storelanguagereducer);
  const toastmsg = (msg) => {toast(msg)};
  const stripe = useStripe();
  const elements = useElements();
  let CartData = props.cartData.state;
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //  console.log('CartData',CartData)
  const handleSubmit = async (event) => {
    // console.log('clientSecret', props.clientSecret)
    event.preventDefault();
    const { paymentIntent, error } = await stripe.confirmCardPayment(
      props.clientSecret,
      {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: CartData?.cartdata?.state?.username,
            address: {
              line1: "123 Main St",
              city: "City",
              state: "State",
              postal_code: "12345",
              country: "US",
            },
          },
        },
      }
    );

    if (error) {
      // console.log('Payment failed:', error.message);
      if(error.code === "incomplete_number") { 
        toast.error(`${storelanguage === "English" ? "Your card number is incomplete." : "Tu número de tarjeta está incompleto"}`)
      } else if (error.code === "incomplete_expiry") {
        toast.error(`${storelanguage === "English" ? "Your card's expiration date is incomplete." : "La fecha de vencimiento de su tarjeta está incompleta."}`)
      } else if (error.code === "incomplete_cvc") {
        toast.error(`${storelanguage === "English" ? "Your card's security code is incomplete." : "El código de seguridad de su tarjeta está incompleto."}`)
      } else if (error.code === "invalid_number") {
        toast.error(`${storelanguage === "English" ? "Your card number is invalid." : "Su número de tarjeta no es válido."}`)
        
      } else if (error.code === "invalid_expiry") {
        toast.error(`${storelanguage === "English" ? "Your card's expiration date is invalid." : "El año de vencimiento de su tarjeta ya pasó."}`)
        
      } else if (error.code === "invalid_expiry_year") {
        toast.error(`${storelanguage === "English" ? "Your card's expiration year is invalid." : "El año de vencimiento de su tarjeta no es válido."}`)
        
      } else if (error.code === "invalid_cvc") {
        toast.error(`${storelanguage === "English" ? "Your card's security code is invalid." : "El código de seguridad de su tarjeta no es válido."}`)
  
      } else if (error.code === "invalid_expiry_year_past") { 
        toast.error(`${storelanguage === "English" ? "Your card's expiration year is in the past." : "El año de vencimiento de su tarjeta ya pasó."}`)

      } else if (error.code === "card_declined") {
        toast.error(`${storelanguage === "English" ? "Your card has been declined." : "Su tarjeta ha sido rechazada"}`)
        
      } else {
        toast.error(error.message);
      }
      console.error("Order Payment", error)
    } else {
      // Payment successful, retrieve payment reference from paymentIntent
      const paymentReference = paymentIntent.id;
      // console.log('Payment reference:', paymentReference);
      if (paymentIntent.status === "succeeded") {
        // console.log('props.cardData',props.cartData)

        orderconfirm(props.cartData, "Stripe",paymentReference, cart, totalPrice, storelanguage)
          .then((res) => {
            toast.success(res.data.message);
            // console.log("Order Confirm Success", res.data)
            dispatch(confirmedCart(cart, totalitems, totalPrice));
            dispatch(clearCart())
            navigate("/congratulations", { state: props.cartData });
            localStorage.clear()
            sessionStorage.clear()
          })
          .catch((err) => {
            toast.error(err.data.message);
            console.error("Order Confirm Error", err.data)
          });
      }
      // Process payment reference or perform other actions
    }
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (!stripe || !elements) {
  //     // Stripe.js has not yet loaded.
  //     // Make sure to disable form submission until Stripe.js has loaded.
  //     return;
  //   }
  //   setIsProcessing(true);

  //   const { error } = await stripe.confirmPayment({
  //     elements,
  //     confirmParams: {
  //       // Make sure to change this to your payment completion page
  //       return_url: `${window.location.origin}/congratulations` ,
  //     },
  //   },
  //   {
  //    metadata:props.cartData.state
  //   }
  //   ).then(function(result) {
  //     if (result.error) {
  //       // Inform the customer that there was an error.
  //       console.log(result.error.message);
  //     } else {
  //       // Handle next step based on PaymentIntent's status.

  //       console.log("PaymentIntent ID: " + result.paymentIntent.id);
  //       console.log("PaymentIntent status: " + result.paymentIntent.status);
  //     }
  //   })

  //   if (error.type === "card_error" || error.type === "validation_error") {
  //     setMessage(error.message);
  //   } else  {
  //     setMessage("An unexpected error occured.");
  //   }

  //   setIsProcessing(false);
  // };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        {/* {console.log('props.cartData',props.cartData)} */}
        {/* <PaymentElement id="payment-element" /> */}
        {/* <CardElement /> */}
        <div className="checkout_page_card_element_wrapper_div">
          {storelanguage === "English" ? (
            <p>Card Number</p>
          ) : (
            <p>Número de tarjeta</p>
          )}
          <CardNumberElement
            className="checkout_page_card_element"
            placeholder="1234 1234 1234 1234"
            id="card_number"
          />
        </div>
        <div className="checkout_page_card_element_wrapper">
          <div className="checkout_page_card_element_wrapper_div">
            {storelanguage === "English" ? (
              <p>Card Expiry</p>
            ) : (
              <p>Caducidad de la tarjeta</p>
            )}
            <CardExpiryElement
              type="text"
              className="checkout_page_card_element"
              id="card_expire_date"
            />
          </div>
          <div className="checkout_page_card_element_wrapper_div">
            {storelanguage === "English" ? <p>Card CVC</p> : <p>CVC</p>}
            <CardCvcElement
              className="checkout_page_card_element"
              placeholder="123"
              type="text"
            />
          </div>
        </div>
        {/* <button className="Stripe_payment_button" disabled={isProcessing || !stripe || !elements} id="submit">
        <span id="button-text">
          {isProcessing ? "Processing ... " : "Pay now"}
        </span>
      </button> */}
        <button className="Stripe_payment_button" type="submit">
          {storelanguage === "English" ? "Pay" : "Pagar"}
        </button>
        <img
          className="PowredBY_stripe"
          src="/assets/images/stripe-powered.svg"
          alt=""
        />
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>

    </>
  );
}
