import { useSelector } from "react-redux";
import Banner from "./Banner";
import { currencyFormatter } from "../utils";

function ProductPageHeader(props) {
  const { storelanguage } = useSelector((state) => state.storelanguagereducer);
  const { storeCurrency } = useSelector((state) => state.storeCurrencyReducer);

  return (
    <>
      <div
        className={
          props.open
            ? "Product_page_header_wrapper_two"
            : "Product_page_header_wrapper"
        }
      >
        <div
          className={`Product_page_outer_div h-[170px] relative z-10 bg-bottom bg-cover rounded-b-md`}
          style={{
            backgroundImage: `url(${props?.storedata?.state?.item?.banner})`,
          }}
        >
          {!props?.discountBannerValue && (
          <div className="Product_page_header_upper">
            <span className="store__name">
              {storelanguage === "English" ? (
                <>{props.storedata?.state?.item?.en.name}</>
              ) : (
                <>{props.storedata?.state?.item?.it.name}</>
              )}
            </span>
          </div>
          )}
        </div>
        {!!props?.discountBannerValue && (
          <Banner
            text={
              <>
                <p className="flex items-center justify-center h-full !font-bold !text-[14px] text-center text-white ">
                  Minimum {currencyFormatter(props?.discountBannerValue, storeCurrency)} spend per order
                </p>
                <div className="flex justify-center items-end rounded-[10px] relative">
                <span className="absolute w-max bg-white text-[var(--theme-primary)] -bottom-7 p-[5px_30px] font-bold text-[18px] rounded-[10px] shadow-[0px_0px_17px_#00000029]">
                  {storelanguage === "English" ? (
                    <>{props.storedata?.state?.item?.en.name}</>
                  ) : (
                    <>{props.storedata?.state?.item?.it.name}</>
                  )}
                </span>
                </div>
              </>
            }
          />
        )}
      </div>
    </>
  );
}

export default ProductPageHeader;
