export const currencyConstant={
    STORE_CURRENCY:"STORE_CURRENCY"
}
export const languageConstant={
    STORE_LANGUAGE:"STORE_LANGUAGE"
}
export const mapIdConstant={
    STORE_MAPID:"STORE_MAPID"
}
export const discountConstant={
    STORE_DISCOUNT:"STORE_DISCOUNT"
}