import {
  currencyConstant,
  discountConstant,
  languageConstant,
  mapIdConstant,
} from "../Constants";

export const totalItemsCount = (cart) => {
  let totalItems = 0;
  cart.map((cartItem) => {
    totalItems += 1;
    // if (Array.isArray(Object.values(cartItem)[0])) {
    //   totalItems += 1;
    // } else {
    //   totalItems += Object.values(cartItem)[0];
    // }
    return null;
  });
  return totalItems;
};

const haveSameVariantId = (arr1, arr2) => {
  return (
    arr1.every((item1) => arr2.some((item2) => item2.id === item1.id)) &&
    arr2.every((item2) => arr1.some((item1) => item1.id === item2.id))
  );
};

export const IncrementCount =
  (item, cart = [], totalprice, selectedVariantDetails) =>
  async (dispatch) => {
    // console.log(selectedVariantDetails, "Selected Variant Details")

    if (selectedVariantDetails && Array.isArray(selectedVariantDetails)) {
      let tempArray = [];
      selectedVariantDetails.map((variantDetail) => {
        tempArray.push({
          [variantDetail.variantProduct.variantId]: 1,
          product: variantDetail.product,
          productVariant: variantDetail.variantProduct,
        });
        return null;
      });

      cart.push({
        [selectedVariantDetails[0].product._id]: tempArray,
      });
      totalprice += selectedVariantDetails[0].product.price;
    } else if (
      selectedVariantDetails &&
      !Array.isArray(selectedVariantDetails)
    ) {
      let tempArray = [];
      if (selectedVariantDetails?.multipleVariants?.length > 0) {
        let multipleVariantProduct = [];
        let multipleVariantId = "";

        selectedVariantDetails?.multipleVariants?.map(
          (variantDetail, index) => {
            if (index === 0) {
              multipleVariantId += variantDetail.variantId;
            } else {
              multipleVariantId += `${"_" + variantDetail.variantId}`;
            }
            multipleVariantProduct.push(variantDetail);
            return null;
          }
        );

        tempArray.push({
          [multipleVariantId]: 1,
          product: selectedVariantDetails?.product,
          productVariant:
            selectedVariantDetails?.variantProduct,
          multipleVariants: multipleVariantProduct,
        });
      } else {
        tempArray.push({
          [selectedVariantDetails.variantProduct.variantId]: 1,
          product: selectedVariantDetails.product,
          productVariant: selectedVariantDetails.variantProduct,
        });
      }

      cart.push({ [selectedVariantDetails.product._id]: tempArray });
      totalprice += selectedVariantDetails.product.price;
    } else if (!selectedVariantDetails) {
      let cartItemIndex = cart.findIndex(
        (cartItem) => Object.keys(cartItem)[0] === item._id
      );
      if (cartItemIndex > -1) {
        cart[cartItemIndex][item._id] += 1;
        // cart[item._id] += 1;
      } else {
        cart.push({ [item._id]: 1, product: item });
      }
      totalprice += item.price;
    }

    // if (!selectedVariantDetails && cart.hasOwnProperty(selectedVariantDetails.variantProduct.variantId)) {
    //   // console.log("MIN SELECT", selectedVariantDetails.minSelect)
    //   cart[selectedVariantDetails.variantId] += 1;
    // } else if(selectedVariantDetails){
    //   cart[selectedVariantDetails.variantId] = 1;
    // }

    // if (!selectedVariantDetails && cart.hasOwnProperty(item._id)) {
    //   cart[id] += 1;
    // } else if(!selectedVariantDetails){
    //   cart[id] = 1;
    // }

    let totalitems = totalItemsCount(cart);

    // if (selectedVariantDetails) {
    //   totalprice += item.price;
    // } else if (!selectedVariantDetails) {
    //   totalprice += item.price;
    // }

    dispatch({
      type: "INCREMENT_COUNT",
      payload: {
        cart: cart,
        totalitems: totalitems,
        totalPrice: totalprice,
      },
    });
  };

// export const removeItemFromCartVariant = (item, cart = [], totalprice, quantity) =>
// async (dispatch) => {
//   cart.map((cartItem, index) => {
//     if (Object.keys(cartItem)[0] === item._id) {
//       cart.splice(index, 1);
//     }
//     return null;
//   });
//   totalprice -= item.price * quantity;
//   let totalitems = totalItemsCount(cart);
//   dispatch({
//     type: "ITEM_REMOVED_FROM_CART",
//     payload: {
//       cart: cart,
//       totalitems: totalitems,
//       totalPrice: totalprice,
//     },
//   });
// };

export const removeItemFromCart =
  (item, cart = [], totalprice, quantity) =>
  async (dispatch) => {
    let tempCart = cart.filter(
      (cartItem, index) => Object.keys(cartItem)[0] !== item._id
    );

    totalprice -= item.price * quantity;
    let totalitems = totalItemsCount(cart);
    dispatch({
      type: "ITEM_REMOVED_FROM_CART",
      payload: {
        cart: tempCart,
        totalitems: totalitems,
        totalPrice: totalprice,
      },
    });
  };

export const confirmedCart = (cart = [], totalitems, totalprice) => async (dispatch) => {
  dispatch({
    type: "CONFIRMED_CART",
    payload: {
      confirmedCart: cart,
      confirmedTotalItems: totalitems,
      confirmedTotalPrice: totalprice,
    },
  });
}

export const clearConfirmedCart = () => async (dispatch) => {
  dispatch({
    type: "CLEAR_CONFIRMED_CART",
    payload: {
      confirmedCart: [],
      confirmedTotalItems: 0,
      confirmedTotalPrice: 0,
    },
  });
};

export const clearCart = () => async (dispatch) => {
  dispatch({
    type: "CLEAR_CART",
    payload: {
      cart: [],
      totalitems: 0,
      totalPrice: 0,
    },
  });
};

export const DecrementCount =
  (item, cart = [], totalprice, selectedVariantDetails) =>
  async (dispatch) => {
    // console.log(selectedVariantDetails, "Selected Variant Details");
    if (selectedVariantDetails && Array.isArray(selectedVariantDetails)) {
      let tempIndicesArray = [];

      cart.map((cartItem, index) => {
        if (
          Object.keys(cartItem)[0] === selectedVariantDetails[0].product._id
        ) {
          tempIndicesArray.push(index);
        }
        return null;
      });

      cart.splice(tempIndicesArray[tempIndicesArray.length - 1], 1);
      totalprice -= selectedVariantDetails[0].product.price;
    } else if (
      selectedVariantDetails &&
      !Array.isArray(selectedVariantDetails)
    ) {
      let tempIndicesArray = [];

      let multipleVariantId = "";

      if (selectedVariantDetails?.multipleVariants?.length > 0) {
        selectedVariantDetails?.multipleVariants?.map(
          (variantDetail, index) => {
            if (index === 0) {
              multipleVariantId += variantDetail.variantId;
            } else {
              multipleVariantId += `${"_" + variantDetail.variantId}`;
            }
            return null;
          }
        );
      }

      cart.map((cartItem, index) => {
        if (Object.keys(cartItem)[0] === selectedVariantDetails.product._id) {
          cartItem[Object.keys(cartItem)[0]].map((variantItem, idx) => {
            if (
              selectedVariantDetails?.multipleVariants?.length > 0 &&
              Object.keys(variantItem)[0] === multipleVariantId
            ) {
              tempIndicesArray.push(index);
            } else if (
              Object.keys(variantItem)[0] ===
              selectedVariantDetails.variantProduct.variantId
            ) {
              tempIndicesArray.push(index);
            }
            return null;
          });
        }
        return null;
      });

      cart.splice(tempIndicesArray[tempIndicesArray.length - 1], 1);
      totalprice -= selectedVariantDetails.product.price;
    } else if (!selectedVariantDetails) {
      let cartItemIndex = cart.findIndex(
        (cartItem) => Object.keys(cartItem)[0] === item._id
      );
      if (cartItemIndex > -1) {
        cart[cartItemIndex][item._id] -= 1;
        if (cart[cartItemIndex][item._id] === 0) {
          cart.splice(cartItemIndex, 1);
        }
        totalprice -= item.price;
        // cart[item._id] += 1;
      }
    }

    let totalitems = totalItemsCount(cart);

    dispatch({
      type: "DECREMENT_COUNT",
      payload: {
        cart: cart,
        totalitems: totalitems,
        totalPrice: totalprice,
      },
    });
  };

export const mergeVariants =
  (cart, tempCart, tempTotalPrice, totalitems, totalPrice) =>
  async (dispatch) => {
    let totalitemsTemp = totalItemsCount(tempCart);

    dispatch({
      type: "INCREMENT_COUNT",
      payload: {
        cart: [...cart, ...tempCart],
        totalitems: totalitems + totalitemsTemp,
        totalPrice: tempTotalPrice + totalPrice,
      },
    });
  };

export const ItemsList = (data) => {
  return {
    type: "ITEMS_LIST_STORING",
    payload: data,
  };
};
export const otpaction = (data) => {
  return {
    type: "OTP_NUMBER",
    payload: data,
  };
};

export const paymentaction = (item) => async (dispatch) => {
  dispatch({ type: "Payment_Refrence", payload: item });
};

export const storecurrency = (currency) => async (dispatch) => {
  dispatch({
    type: currencyConstant.STORE_CURRENCY,
    payload: currency,
  });
};
export const discountDetails = (discountDetails) => async (dispatch) => {
  dispatch({
    type: discountConstant.STORE_DISCOUNT,
    payload: discountDetails,
  });
}; 
export const storelanguageaction = (language) => async (dispatch) => {
  dispatch({
    type: languageConstant.STORE_LANGUAGE,
    payload: language,
  });
};
export const storemapIdaction = (mapId) => async (dispatch) => {
  // console.log('mapId', mapId)
  dispatch({
    type: mapIdConstant.STORE_MAPID,
    payload: mapId,
  });
};

// export const clearCart = () => async (dispatch) => {
//   dispatch({
//     type: "CLEAR_CART",
//   });
// };
