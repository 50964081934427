import {
  Divider,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  ThemeProvider,
  ToggleButton,
  ToggleButtonGroup,
  createTheme,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MuiToggleButton from "@mui/material/ToggleButton";
import { findIndex } from "lodash";

const VariantToggleButtonGroup = ({
  variant,
  selectedVariantDetails,
  setSelectedVariantDetails,
  product,
  selectedTempVariantDetails,
  setSelectedTempVariantDetails,
  multipleVariants,
  setMultipleVariants,
  variantIndex
  // variantIndex,
  // variantLength
}) => {
  const { storelanguage } = useSelector((state) => state.storelanguagereducer);
  const [selectedVariant, setSelectedVariant] = useState(() => {
    if (variant.minSelect && variant.minSelect >= 2) {
      let tempArray = [];
      variant.product.map((product, index) => {
        if (variant.minSelect > index) {
          tempArray.push(storelanguage === "English"? product.name.en : product.name.it );
        }
        return null;
      });

      let anotherTempArray = [];
      tempArray.map((productName) =>
        anotherTempArray.push({
          variantProduct: variant.product.find(
            (pdct) => storelanguage === "English"? pdct.name.en === productName : pdct.name.it === productName
          ),
          variant: variant,
          product: product,
        })
      );

      setSelectedVariantDetails(anotherTempArray);
      setSelectedTempVariantDetails(anotherTempArray);
      return tempArray;
    } else if(variant.minSelect && variant.minSelect === 1 && product?.variant?.length > 1) {
      // setMultipleVariants([...multipleVariants, variant])
      multipleVariants.push({
        variantProduct: variant.product[0],
        variant: variant,
        product: product,
      })
      setMultipleVariants(multipleVariants)

      setSelectedVariantDetails({
        variantProduct: variant.product[0],
        variant: variant,
        product: product,
      });

      setSelectedTempVariantDetails({
        variantProduct: variant.product[0],
        variant: variant,
        product: product,
      });

      return storelanguage === "English"? variant.product[0].name.en : variant.product[0].name.it;
    } else {
      setSelectedVariantDetails({
        variantProduct: variant.product[0],
        variant: variant,
        product: product,
      });
      setSelectedTempVariantDetails({
        variantProduct: variant.product[0],
        variant: variant,
        product: product,
      });
      return storelanguage === "English"? variant.product[0].name.en : variant.product[0].name.it;
    }
  });

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    "& .MuiToggleButtonGroup-grouped": {
      border: 0,
      "&.Mui-disabled": {
        border: 0,
      },
      "&:not(:first-of-type)": {
        borderRadius: theme.shape.borderRadius,
      },
      "&:first-of-type": {
        borderRadius: theme.shape.borderRadius,
      },
    },
  }));

  const StyledToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "black",
      backgroundColor: "white",
      // border: '1px solid var(--theme-primary) !important'
    },
  }));

  const toggleVariantTheme = createTheme({
    palette: {
      text: {
        primary: "#00ff00",
        textWrap: "wrap",
      },
    },
  });

  const handleVariantChange = (
    event,
    changedVariant,
    variantProduct,
    variant,
    product
  ) => {
    setSelectedVariant(changedVariant);
    if (Array.isArray(changedVariant)) {
      let anotherTempArray = [];
      changedVariant.map((productName) =>
        anotherTempArray.push({
          variantProduct: variant.product.find(
            (pdct) => storelanguage === "English"? pdct.name.en === productName : pdct.name.it === productName
          ),
          variant: variant,
          product: product,
        })
      );

      setSelectedVariantDetails(anotherTempArray);
      setSelectedTempVariantDetails(anotherTempArray);
    } else if(variant.minSelect && variant.minSelect === 1 && product?.variant?.length > 1) {
      if(product?.variant[variantIndex]?.product?.find((pdct) => storelanguage === "English"? pdct.name.en === changedVariant : pdct.name.it === changedVariant)) {
        let variantFound = variantProduct.find(
          (pdct) => storelanguage === "English"? pdct.name.en === changedVariant : pdct.name.it === changedVariant
        )
        multipleVariants[variantIndex] = {
          variantProduct: variantFound,
          variant: variant,
          product: product,
        }
      }

      setSelectedVariantDetails({
        variantProduct: variantProduct.find(
          (pdct) => storelanguage === "English"? pdct.name.en === changedVariant : pdct.name.it === changedVariant
        ),
        variant: variant,
        product: product,
      });

      setSelectedTempVariantDetails({
        variantProduct: variantProduct.find(
          (pdct) => storelanguage === "English" ? pdct.name.en === changedVariant : pdct.name.it === changedVariant
        ),
        variant: variant,
        product: product,
      });
    } else {
      setSelectedVariantDetails({
        variantProduct: variantProduct.find(
          (pdct) => storelanguage === "English"? pdct.name.en === changedVariant : pdct.name.it === changedVariant
        ),
        variant: variant,
        product: product,
      });
      setSelectedTempVariantDetails({
        variantProduct: variantProduct.find(
          (pdct) => storelanguage === "English" ? pdct.name.en === changedVariant : pdct.name.it === changedVariant
        ),
        variant: variant,
        product: product,
      });
      // console.log(product.find((pdct) => pdct.name === changedVariant));
    }
  };

  //   useEffect(() => {
  //     setSelectedVariantDetails(
  //       variant.product.find((pdct) => pdct.name === defaultVariant)
  //     );
  //     console.log(selectedVariantDetails, "s")
  // },[defaultVariant, selectedVariantDetails, setSelectedVariantDetails, variant.product])

  return (
    <ThemeProvider theme={toggleVariantTheme}>
      <RadioGroup
        sx={{
          display: "flex",
          flexWrap: "wrap",
          marginTop: "15px",
          // width: "100%",
          flexDirection: "row",
          marginInline: "30px",
        }}
        value={selectedVariant}
        onChange={(event, changedVariant) => {
          if (
            changedVariant &&
            Array.isArray(changedVariant) &&
            changedVariant.length === variant.minSelect &&
            changedVariant.length <= variant.maxSelect
          ) {
            handleVariantChange(
              event,
              changedVariant,
              variant.product,
              variant,
              product
            );
          } else if (
            changedVariant &&
            Array.isArray(changedVariant) &&
            changedVariant.length === variant.maxSelect + 1
          ) {
            changedVariant.shift();
            handleVariantChange(
              event,
              changedVariant,
              variant.product,
              variant,
              product
            );
          } else if (
            changedVariant &&
            variant.minSelect < 2 &&
            variant.maxSelect < 2
          ) {
            handleVariantChange(
              event,
              changedVariant,
              variant.product,
              variant,
              product
            );
          }
        }}
        aria-labelledby="variant"
        name="product__variants"
      >
        {variant.product.map((product, index) => (
          <FormControlLabel
            key={"variant__no__" + index}
            value={storelanguage === "English" ? product.name.en : product.name.it}
            control={<Radio />}
            label={storelanguage === "English" ? product.name.en : product.name.it}
            aria-label={storelanguage === "English" ? product.name.en : product.name.it}
            sx={{
              width: "50%",
              fontSize: "12px",
              marginBottom: "10px",
              justifyContent: "start",
              color: "black",
              margin: "0",
              display: "flex",
              fontFamily: "Poppins",
              // textOverflow: "ellipsis"
            }}
          />
        ))}
      </RadioGroup>

      {/* <StyledToggleButtonGroup
        size="small"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          marginTop: "15px",
          width: "100%"
        }}
        value={selectedVariant}
        exclusive={variant.minSelect >= 2 ? false : true}
        onChange={(event, changedVariant) => {
          if (
            changedVariant &&
            Array.isArray(changedVariant) &&
            changedVariant.length === variant.minSelect &&
            changedVariant.length <= variant.maxSelect
          ) {
            handleVariantChange(
              event,
              changedVariant,
              variant.product,
              variant,
              product
            );
          } else if (
            changedVariant &&
            Array.isArray(changedVariant) &&
            changedVariant.length === variant.maxSelect + 1
          ) {
            changedVariant.shift();
            handleVariantChange(
              event,
              changedVariant,
              variant.product,
              variant,
              product
            );
          } else if (
            changedVariant &&
            variant.minSelect < 2 &&
            variant.maxSelect < 2
          ) {
            handleVariantChange(
              event,
              changedVariant,
              variant.product,
              variant,
              product
            );
          }
        }}
      >
        {variant.product.map((product, index) => (
          <StyledToggleButton
            key={"product__no__" + index}
            value={product.name}
            aria-label={product.name}
            sx={{
              width: "50%",
              fontSize: "12px",
              marginBottom: "10px",
              justifyContent: "start",
              color: "black"
            }}
          >
            {product.name}
          </StyledToggleButton>
        ))}
      </StyledToggleButtonGroup> */}
    </ThemeProvider>
  );
};

export default VariantToggleButtonGroup;
